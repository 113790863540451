import { fetchCoworkers } from '@/shared/api/offer/read';
import { RefreshOptions } from '@/shared/enums/types';

let coworkers: Record<string, string>;

export async function list(options?: RefreshOptions): Promise<Record<string, string>> {
  if (!coworkers || options?.refresh) {
    coworkers = await fetchCoworkers();
  }

  return coworkers;
}
