import { AbortOptions } from '@/shared/dataProviders/common';
import { usePostEnumDataProvider } from '@/shared/dataProviders/offer';
import { useAsyncEnum } from '@/shared/enums/useAsyncEnum';

// Could return the same async enum to avoid concurrent use
export const usePostPartnerExchangesEnum = () =>
  useAsyncEnum(
    'postPartnerExchanges',
    (options?: AbortOptions) => usePostEnumDataProvider().fetchPartnerExchanges(options),
    { sort: { by: 'text' } }
  );
