import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { cancellable } from '@/shared/api/util/cancellable';
import { DeleteRestOptions, RestClient, RestOptions, RestResponse } from '@/shared/dataProviders/common/RestClient';

async function wrapRequest<T, E>(request: Promise<AxiosResponse<T>>): Promise<RestResponse<T, E>> {
  try {
    const response = await cancellable(request);

    return {
      isOk: true,
      data: response.data,
    };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      return {
        isOk: false,
        data: e.response.data,
      };
    }

    throw e;
  }
}

export class AxiosRestClient implements RestClient {
  constructor(private readonly axios: AxiosInstance) {}

  async delete<T, E, D>(url: string, options?: DeleteRestOptions<D>): Promise<RestResponse<T, E>> {
    return await wrapRequest(
      this.axios.delete<T>(url, {
        data: options?.data,
        params: options?.params,
        signal: options?.signal,
      })
    );
  }

  async get<T, E>(url: string, options?: RestOptions): Promise<RestResponse<T, E>> {
    return await wrapRequest(
      this.axios.get<T>(url, {
        params: options?.params,
        signal: options?.signal,
      })
    );
  }

  async post<T, D, E>(url: string, data?: D, options?: RestOptions): Promise<RestResponse<T, E>> {
    return await wrapRequest(
      this.axios.post<T>(url, data, {
        params: options?.params,
        signal: options?.signal,
      })
    );
  }

  async put<T, D, E>(url: string, data?: D, options?: RestOptions): Promise<RestResponse<T, E>> {
    return await wrapRequest(
      this.axios.put<T>(url, data, {
        params: options?.params,
        signal: options?.signal,
      })
    );
  }
}
