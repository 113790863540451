import { inject, provide, ref } from 'vue';

interface Dropdown {
  close: () => void;
  toggle: () => void;
}

const dropdownSymbol = Symbol();

export function useDropdown() {
  const isOpened = ref(false);

  function close() {
    isOpened.value = false;
  }

  function toggle() {
    isOpened.value = !isOpened.value;
  }

  provide<Dropdown>(dropdownSymbol, inject<Dropdown>(dropdownSymbol, { close, toggle }));

  return {
    isOpened,

    close,
    toggle,
  };
}

export function useDropdownItem() {
  const dropdown = inject<Dropdown>(dropdownSymbol);

  if (!dropdown) {
    throw new Error('BaseNavbarDropdownItem should be inside a BaseNavbarDropdown');
  }

  return {
    close: () => {
      dropdown.close();
    },
  };
}
