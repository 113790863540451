import { DateTime } from 'luxon';

import { AbortOptions, Collection, PaginationOptions, SortingOptions } from '@/shared/dataProviders/common';
import {
  B2PWebCollection,
  B2PWebError,
  handleB2PWebResponse,
  mapToCollection,
} from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { GoodType } from '@/shared/enums/goodType';
import { TruckType } from '@/shared/enums/truckType';

import { B2PWebEphemeralSearchOffer } from './api';
import { mapToEphemeralSearchOffer } from './mappers';
import { EphemeralSearchOffer } from './types';

export interface PartnerOffersListFiltersValues {
  exchange?: string;
  goodType?: GoodType;
  truckTypes?: TruckType[];
  fromCountry?: string;
  toCountry?: string;
  fromZones?: string[];
  toZones?: string[];
  since?: DateTime;
  hazardousMaterials?: boolean;
  minWeight?: number;
  maxWeight?: number;
  minLength?: number;
  maxLength?: number;
  minVolume?: number;
  maxVolume?: number;
}

export class PartnerOffersDataProvider {
  constructor(private readonly client: RestClient) {}

  async list(
    options: PartnerOffersListFiltersValues & AbortOptions & PaginationOptions & SortingOptions
  ): Promise<Collection<EphemeralSearchOffer>> {
    const collection = handleB2PWebResponse(
      await this.client.get<B2PWebCollection<B2PWebEphemeralSearchOffer>, B2PWebError>('/partner/offers', {
        params: {
          exchanges: options.exchange ? [options.exchange] : [],
          goodType: options.goodType,
          truckTypes: options.truckTypes,
          fromCountry: options.fromCountry,
          toCountry: options.toCountry,
          fromZones: options.fromZones,
          toZones: options.toZones,
          since: options.since?.toFormat('yyyy-MM-dd'),
          hazardousMaterials: options.hazardousMaterials,
          minWeight: options.minWeight,
          maxWeight: options.maxWeight,
          minLength: options.minLength,
          maxLength: options.maxLength,
          minVolume: options.minVolume,
          maxVolume: options.maxVolume,
          page: options.page,
          perPage: options.perPage,
          sort: options.sort,
        },
        signal: options?.signal,
      })
    );

    return mapToCollection(collection, mapToEphemeralSearchOffer);
  }
}
