import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import { get as readFromLocalStorage, set as writeToLocalStorage } from '@/store/localStorage';

export enum FontSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export const STORAGE_KEY_FONT_SIZE = 'ui.fontSize';

export const useUISettings = defineStore('ui-settings', () => {
  const fontSize = ref(readFromLocalStorage(STORAGE_KEY_FONT_SIZE, FontSize.Medium));

  watch(fontSize, () => {
    writeToLocalStorage(STORAGE_KEY_FONT_SIZE, fontSize.value);
  });

  return {
    fontSize,
    setFontSize(newFontSize: FontSize) {
      fontSize.value = newFontSize;
    },
  };
});
