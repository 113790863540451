import { AbortOptions } from '@/shared/dataProviders/common';
import { usePartnerEnumDataProvider } from '@/shared/dataProviders/partner';
import { useAsyncEnum } from '@/shared/enums/useAsyncEnum';

// Could return the same async enum to avoid concurrent use
export const useMyCommunityPartnerExchangesEnum = () =>
  useAsyncEnum(
    'myCommunityPartnerExchanges',
    (options?: AbortOptions) => usePartnerEnumDataProvider().fetchMyCommunities(options),
    { sort: { by: 'text' } }
  );
