import { AbortOptions, Collection, PaginationOptions } from '@/shared/dataProviders/common';
import {
  B2PWebCollection,
  B2PWebError,
  handleB2PWebResponse,
  mapToCollection,
} from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { B2PWebSearchTemplate } from '@/shared/dataProviders/search/api';
import { mapToSearchTemplate } from '@/shared/dataProviders/search/mappers';

import { SearchTemplate } from './types';

export class SearchTemplateDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchAll(options?: AbortOptions & PaginationOptions): Promise<Collection<SearchTemplate>> {
    const collection = handleB2PWebResponse(
      await this.client.get<B2PWebCollection<B2PWebSearchTemplate>, B2PWebError>('/searchTemplates', {
        params: {
          page: options?.page,
          perPage: options?.perPage,
        },
        signal: options?.signal,
      })
    );

    return mapToCollection(collection, mapToSearchTemplate);
  }

  async removeAll(ids: string[], options?: AbortOptions): Promise<void> {
    await Promise.all(ids.map((id) => this.remove(id, options)));
  }

  async remove(id: string, options?: AbortOptions): Promise<void> {
    return handleB2PWebResponse(
      await this.client.delete(`/searchTemplates/${id}`, {
        signal: options?.signal,
      })
    );
  }
}
