import { DateTime } from 'luxon';

import {
  ApiActiveSearch,
  ApiCollection,
  ApiConsultant,
  ApiRealOffer,
  ApiRealOfferCarrier,
  ApiRealOfferContact,
  ApiRealOfferCustomerContact,
  ApiRealOfferInput,
  ApiRealOfferLocation,
  ApiRealOfferShipment,
} from '@/shared/api/offer/types-api';
import { GoodType } from '@/shared/enums/goodType';
import { isTruckType } from '@/shared/enums/truckType';

import {
  ActiveSearch,
  Collection,
  Consultant,
  Good,
  RealOffer,
  RealOfferCarrier,
  RealOfferContact,
  RealOfferCustomerContact,
  RealOfferInput,
  RealOfferLocation,
  RealOfferShipment,
} from './types';

export function composeApiRealOfferInput(input: RealOfferInput): ApiRealOfferInput {
  return {
    orderRef: input.orderRef,
    goodType: input.goodType,
    truckTypes: input.truckTypes,
    hazardousMaterials: input.hazardousMaterials,
    lading: {
      label: input.lading.label,
      address: input.lading.address,
      city: input.lading.city,
      zone: input.lading.zone,
      zipCode: input.lading.zipCode,
      country: input.lading.country,
      latitude: input.lading.latitude,
      longitude: input.lading.longitude,
      dateStart: input.lading.date.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
    },
    delivery: {
      label: input.delivery.label,
      address: input.delivery.address,
      city: input.delivery.city,
      zone: input.delivery.zone,
      zipCode: input.delivery.zipCode,
      country: input.delivery.country,
      latitude: input.delivery.latitude,
      longitude: input.delivery.longitude,
      dateStart: input.delivery.date.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
    },
    shipment: {
      id: input.shipment.id,
      classType: input.shipment.classType,
      deferPaymentInDays: input.shipment.deferPaymentInDays,
      goods: input.shipment.goods?.map((good) => ({ ...good })),
      hazardousProduct: input.shipment.hazardousProduct,
      onuNumber: input.shipment.onuNumber,
      packageNb: input.shipment.packageNb,
      registration: input.shipment.registration,
      strapNb: input.shipment.strapNb,
    },
    isFull: input.shipment.isFull,
    returnablePackage: input.shipment.hasReturnablePackage,
    sender: {
      name: input.lading.label,
      address: input.lading.address,
    },
    carrier: {
      address: input.carrier.address,
      zipCode: input.carrier.zipCode,
      city: input.carrier.city,
      contact: {
        id: input.carrier.contact.id,
        name: input.carrier.contact.name,
        email: input.carrier.contact.email,
        fax: input.carrier.contact.fax,
        phone: input.carrier.contact.phone,
      },
      customerContact: {
        id: input.carrier.customerContact.id,
        name: input.carrier.customerContact.name,
        country: input.carrier.customerContact.country,
      },
    },
    recipient: {
      name: input.delivery.label,
      address: input.delivery.address,
    },
    length: input.length,
    weight: input.weight,
    volume: input.volume,
    price: input.price,
    currency: input.currency,
    notes: input.notes,
  };
}

export function composeCollection<Source, Target>(
  source: ApiCollection<Source>,
  composeItems: (items: Source[]) => Target[]
): Collection<Target> {
  return {
    items: composeItems(source.items),
    count: source.pagination.pageCount,
    totalCount: source.pagination.totalCount,
    page: source.pagination.currentPage,
    pageCount: Math.ceil(source.pagination.totalCount / source.pagination.limit),
    perPage: source.pagination.limit,
  };
}

export function composeConsultants(data: ApiConsultant[]): Consultant[] {
  return data.map((item) => composeConsultant(item));
}

export function composeConsultant(data: ApiConsultant): Consultant {
  return {
    id: data.id,
    name: data.name,
    customer: {
      id: data.customer.id,
      name: data.customer.name,
      dateInsert: data.customer.dateInsert ? DateTime.fromISO(data.customer.dateInsert) : null,
    },
    email: data.email,
    phone: data.phone,
  };
}

export function composeActiveSearch(data: ApiActiveSearch): ActiveSearch {
  return {
    id: data.id,
    name: data.name,
    email: data.email,
    phone: data.phone,
    customer: {
      ...data.customer,
      dateInsert: data.customer.dateInsert ? DateTime.fromISO(data.customer.dateInsert) : null,
    },
    lastConsultationDate: DateTime.fromISO(data.lastConsultationDate),
  };
}

export function composeActiveSearches(data: ApiActiveSearch[]): ActiveSearch[] {
  return data.map((item) => composeActiveSearch(item));
}

export function composeRealOffers(data: ApiRealOffer[]): RealOffer[] {
  return data.map((item) => composeRealOffer(item));
}

export function composeRealOffer(data: ApiRealOffer): RealOffer {
  // @note wrong API type. defined as string but integer returned
  const goodType = data.goodType.toString();
  const truckTypes = data.truckTypes.map((truckType) => truckType.toString());

  return {
    id: data.id,
    offerId: data.offerId,
    orderRef: data.orderRef,
    lading: composeRealOfferLocation(data.lading),
    delivery: composeRealOfferLocation(data.delivery),
    distance: data.distance,
    contact: composeRealOfferContact(data.contact),
    customerContact: composeRealOfferCustomerContact(data.customerContact),
    carrier: composeRealOfferCarrier(data.carrier),
    shipment: composeRealOfferShipment(data.shipment),
    goodType: goodType as GoodType,
    truckTypes: truckTypes.filter(isTruckType),
    hazardousMaterials: data.hazardousMaterials,
    notes: data.notes,
    length: data.length,
    volume: data.volume,
    weight: data.weight,
    price: data.price,
  };
}

function composeRealOfferLocation(data: ApiRealOfferLocation): RealOfferLocation {
  return {
    label: data.label,
    address: data.address,
    city: data.city,
    zone: data.zone,
    zipCode: data.zipCode,
    country: data.country,
    latitude: data.latitude,
    longitude: data.longitude,
    date: data.dateStart ? DateTime.fromISO(data.dateStart, { setZone: true }) : null,
  };
}

function composeRealOfferCarrier(data: ApiRealOfferCarrier): RealOfferCarrier {
  return {
    address: data.address,
    zipCode: data.zipCode,
    city: data.city,
    contact: composeRealOfferContact(data.contact),
    customerContact: composeRealOfferCustomerContact(data.customerContact),
  };
}

function composeRealOfferContact(data: ApiRealOfferContact): RealOfferContact {
  return {
    id: data.id,
    civility: data.civility,
    name: data.name,
    email: data.email,
    phone: data.phone,
    mobile: data.mobile,
    fax: data.fax,
    country: data.country,
    languages: data.languages,
  };
}

function composeRealOfferCustomerContact(data: ApiRealOfferCustomerContact): RealOfferCustomerContact {
  return {
    id: data.id,
    name: data.name,
    zone: data.zone,
    country: data.country,
    logo: data.logo,
  };
}

function composeRealOfferShipment(data: ApiRealOfferShipment): RealOfferShipment {
  return {
    id: data.id,
    classType: data.classType,
    deferPaymentInDays: data.deferPaymentInDays,
    goods:
      data.goods?.map((good) => ({
        type: good.type as Good,
        count: good.count,
      })) ?? [],
    hazardousProduct: data.hazardousProduct,
    isFull: data.isFull,
    hasReturnablePackage: data.returnablePackage,
    onuNumber: data.onuNumber,
    packageNb: data.packageNb,
    registration: data.registration,
    strapNb: data.strapNb,
  };
}
