import axios from 'axios';

export class VersionChecker {
  private initialHtml?: string;

  constructor(private readonly url: string) {}

  async hasAvailableUpdate() {
    const html = await this.getFileContent();

    if (!this.initialHtml) {
      this.initialHtml = html;
    }

    return this.initialHtml !== html;
  }

  private async getFileContent() {
    const { data: html } = await axios.get<string>(this.url, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    return html;
  }
}
