import { AbortOptions } from '@/shared/dataProviders/common';
import { B2PWebError, handleB2PWebResponse } from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { B2PWebEnumResponse } from '@/shared/dataProviders/enum/b2pweb';
import { B2PWebExchangeEnum } from '@/shared/dataProviders/search/api';
import { ExchangeEnum } from '@/shared/dataProviders/search/types';

export class SearchEnumDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchExchanges(options?: AbortOptions): Promise<ExchangeEnum[]> {
    return Object.values(
      handleB2PWebResponse(
        await this.client.get<B2PWebEnumResponse<string, string, B2PWebExchangeEnum>, B2PWebError>('/enum/exchanges', {
          signal: options?.signal,
        })
      )
    );
  }
}
