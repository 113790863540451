import { isNaN, isString, toString } from 'lodash-es';

export function parseNumber(value: unknown): number | undefined {
  if (value == undefined) {
    return undefined;
  }

  const parsedValue = parseFloat(value.toString());

  return isNaN(parsedValue) ? undefined : parsedValue;
}

export function parseArrayOfString(values: unknown): string[] | undefined {
  return parseEnums<string>(values, isString);
}

export function parseString(value: unknown): string | undefined {
  return isString(value) ? toString(value) : undefined;
}

export function parseBoolean(value: unknown): boolean | undefined {
  if (value === undefined) {
    return undefined;
  }

  return Boolean(value);
}

export function stringifyBoolean(value: boolean): string {
  return value ? '1' : '';
}

export function parseEnum<T>(value: unknown, exists: (value: unknown) => value is T): T | undefined {
  return exists(value) ? value : undefined;
}

export function parseEnums<T>(value: unknown, filter: (value: unknown) => value is T): T[] | undefined {
  let result = Array.isArray(value) ? value : [value];
  result = result.filter((value) => filter(value));

  return result.length ? result : undefined;
}
