import { AxiosInstance } from 'axios';

import { useAppStore } from '@/store/modules/app';

export function setupUserLanguage(instance: AxiosInstance) {
  instance.interceptors.request.use((config) => {
    const locale = useAppStore().locale;

    if (locale) {
      config.headers.set('Accept-Language', locale);
    }

    return config;
  });
}
