import { DateTime } from 'luxon';

export interface ApiLocation {
  city: string;
  zipCode: string;
  zone: string;
  country: string;
  latitude: number;
  longitude: number;
  dateStart: string | null;
  dateEnd: string | null;
}

export interface Location {
  city: string;
  zipCode: string;
  zone: string;
  country: string;
  latitude: number;
  longitude: number;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export function fromApi(data: ApiLocation): Location {
  return {
    city: data.city,
    zipCode: data.zipCode,
    zone: data.zone,
    country: data.country,
    latitude: data.latitude,
    longitude: data.longitude,
    startDate: data.dateStart ? DateTime.fromISO(data.dateStart, { setZone: true }) : null,
    endDate: data.dateEnd ? DateTime.fromISO(data.dateEnd, { setZone: true }) : null,
  };
}
