import source from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import es from 'i18n-iso-countries/langs/es.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import it from 'i18n-iso-countries/langs/it.json';
import pt from 'i18n-iso-countries/langs/pt.json';

export default function () {
  source.registerLocale(en);
  source.registerLocale(es);
  source.registerLocale(it);
  source.registerLocale(fr);
  source.registerLocale(pt);
}
