import { App, Directive } from 'vue';

import config from '@/shared/config';

export const TestId: Directive = {
  mounted: (el, binding) => {
    // @todo Gérer la suppression de data-testid pour les cas où sa valeur peut changer dynamiquement
    if (config.addTestSelectors && binding.value) {
      el.setAttribute(`data-testid`, binding.value);
    }
  },
};

export const TestIdPlugin = {
  install(app: App): void {
    app.directive('test', TestId);
  },
};
