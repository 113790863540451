export enum CommunityPartnerSortingType {
  ByCustomerNameAsc = '+customerName',
  ByCustomerNameDesc = '-customerName',
  ByCustomerCountryAsc = '+customerCountry',
  ByCustomerCountryDesc = '-customerCountry',
  ByCustomerZipCodeAsc = '+customerZipCode',
  ByCustomerZipCodeDesc = '-customerZipCode',
  ByCustomerCityAsc = '+customerCity',
  ByCustomerCityDesc = '-customerCity',
  ByExchangeLabelAsc = '+exchangeLabel',
  ByExchangeLabelDesc = '-exchangeLabel',
  ByEnabledAsc = '+enabled',
  ByEnabledDesc = '-enabled',
  ByCreatedAtAsc = '+createdAt',
  ByCreatedAtDesc = '-createdAt',
  ByUpdatedAtAsc = '+updatedAt',
  ByUpdatedAtDesc = '-updatedAt',
}
