import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useEnum } from '@/shared/enums/useEnum';
import { PostPartnerToProfileSwitch } from '@/shared/types';

export const usePostPartnerToProfileSwitchEnum = () => {
  const { t } = useI18n();

  return useEnum(
    computed(() => [
      { key: PostPartnerToProfileSwitch.Never, value: t('Jamais') },
      { key: PostPartnerToProfileSwitch.FiveMinutes, value: t('5 minutes') },
      { key: PostPartnerToProfileSwitch.TenMinutes, value: t('10 minutes') },
      { key: PostPartnerToProfileSwitch.FifteenMinutes, value: t('15 minutes') },
      { key: PostPartnerToProfileSwitch.ThirtyMinutes, value: t('30 minutes') },
      { key: PostPartnerToProfileSwitch.OneHour, value: t('60 minutes') },
      { key: PostPartnerToProfileSwitch.TwoHours, value: t('120 minutes') },
      { key: PostPartnerToProfileSwitch.ThreeHours, value: t('180 minutes') },
    ]),
    { sort: { by: 'value', options: { numeric: true } } }
  );
};
