import httpClient, { isAxiosError } from '@/shared/api/admin/client';
import {
  Parameter,
  TimeSettings,
  UserFeedback,
  UserInformation,
  UserParameter,
  UserSettings,
} from '@/shared/api/admin/types';
import { FormValidationError, ParameterNotFoundError } from '@/shared/api/admin/utils';

export async function getParameter(id: string): Promise<Parameter> {
  try {
    const { data } = await httpClient.get<Parameter>(`customer/parameters/${id}`);

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      throw new ParameterNotFoundError(e.response.data.message);
    }

    throw e;
  }
}

export async function setParameter(data: Parameter) {
  try {
    await httpClient.put<Parameter>(`customer/parameters/${data.id}`, { value: data.value });
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while updating parameter', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function getUserInformation(signal?: AbortSignal): Promise<UserInformation> {
  const { data } = await httpClient.get('user', {
    signal,
  });

  return data;
}

export async function replaceUserLanguages(values: string[]): Promise<void> {
  try {
    await httpClient.put('user/languages', {
      values,
    });
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while replacing user languages', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function getUserParameter(id: string, signal?: AbortSignal): Promise<UserParameter | undefined> {
  try {
    const { data } = await httpClient.get(`user/parameters/${id}`, {
      signal,
    });

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return undefined;
    }

    throw e;
  }
}

export async function replaceUserParameter(id: string, value: string): Promise<void> {
  try {
    await httpClient.put(`user/parameters/${id}`, {
      value,
    });
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while replacing parameter', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function getUserSettings(): Promise<UserSettings> {
  const { data } = await httpClient.get<UserSettings>(`user/settings`);

  return data;
}

export async function postUserFeedback(data: UserFeedback) {
  await httpClient.post(`user/feedback`, data);
}

export async function getTimeSettings(): Promise<TimeSettings> {
  const { data } = await httpClient.get<TimeSettings>(`settings/time`);

  return data;
}
