import { DateTime } from 'luxon';

import { mapToCustomerContact } from '@/shared/dataProviders/common/b2pweb';
import { GoodType } from '@/shared/enums/goodType';
import { isLoadingDateType } from '@/shared/enums/loadingDateType';
import { isSound } from '@/shared/enums/sound';
import { TruckType } from '@/shared/enums/truckType';
import { isColor } from '@/shared/model/searchColorPlugin';
import { serializeZones } from '@/shared/utils';

import {
  B2PWebEphemeralSearch,
  B2PWebEphemeralSearchLocation,
  B2PWebEphemeralSearchLocationByCity,
  B2PWebEphemeralSearchLocationByZones,
  B2PWebEphemeralSearchOffer,
  B2PWebLocation,
  B2PWebSearchTemplate,
} from './api';
import {
  EphemeralSearch,
  EphemeralSearchLocation,
  EphemeralSearchLocationByCity,
  EphemeralSearchLocationByZones,
  EphemeralSearchOffer,
  Location,
  SearchTemplate,
} from './types';

export function mapToB2PWebEphemeralSearch(source: EphemeralSearch): B2PWebEphemeralSearch {
  return {
    hazardousMaterials: source.hazardousMaterials,
    goodType: source.goodType,
    truckType: source.truckType,
    loadingDateType: source.loadingDateType,
    dateStart: source.dateStart?.toISODate(),
    dateEnd: source.dateEnd?.toISODate(),
    from: source.from ? mapToB2PWebEphemeralSearchLocation(source.from) : undefined,
    to: source.to ? mapToB2PWebEphemeralSearchLocation(source.to) : undefined,
    maxWeight: source.maxWeight,
    minWeight: source.minWeight,
    maxLength: source.maxLength,
    minLength: source.minLength,
    maxVolume: source.maxVolume,
    exchanges: source.exchanges,
  };
}

function mapToB2PWebEphemeralSearchLocation(source: EphemeralSearchLocation): B2PWebEphemeralSearchLocation {
  return source.type === 'city'
    ? mapToB2PWebEphemeralSearchLocationByCity(source)
    : mapToB2PWebEphemeralSearchLocationByZones(source);
}

function mapToB2PWebEphemeralSearchLocationByCity(
  source: EphemeralSearchLocationByCity
): B2PWebEphemeralSearchLocationByCity {
  return {
    type: 'city',
    latitude: source.latitude,
    longitude: source.longitude,
    radius: source.radius,
  };
}

function mapToB2PWebEphemeralSearchLocationByZones(
  source: EphemeralSearchLocationByZones
): B2PWebEphemeralSearchLocationByZones {
  return {
    type: 'zones',
    country: source.country,
    zones: source.zones ? [serializeZones(source.zones)] : undefined,
  };
}

export function mapToEphemeralSearchOffer(source: B2PWebEphemeralSearchOffer): EphemeralSearchOffer {
  return {
    id: source.id,
    customer: mapToCustomerContact(source.customer),
    forbiddingLevel: source.forbiddingLevel,
    hazardousMaterials: source.hazardousMaterials,
    goodType: source.goodType as GoodType,
    truckTypes: source.truckTypes as TruckType[],
    lading: mapToLocation(source.lading),
    delivery: mapToLocation(source.delivery),
    weight: source.weight,
    length: source.length,
    volume: source.volume,
    distance: source.distance,
    createdAt: DateTime.fromISO(source.createdAt),
    updatedAt: DateTime.fromISO(source.updatedAt),
  };
}

function mapToLocation(source: B2PWebLocation): Location {
  return {
    city: source.city,
    zipCode: source.zipCode,
    zone: source.zone,
    country: source.country,
    latitude: source.latitude,
    longitude: source.longitude,
    startDate: source.dateStart ? DateTime.fromISO(source.dateStart, { setZone: true }) : null,
    endDate: source.dateEnd ? DateTime.fromISO(source.dateEnd, { setZone: true }) : null,
  };
}

export function mapToSearchTemplate(source: B2PWebSearchTemplate): SearchTemplate {
  const loadingDateType = source.loadingDateType?.toString();

  return {
    id: source.id,
    name: source.name,
    shortName: source.shortName,
    color: isColor(source.color) ? source.color : null,
    hazardousMaterials: source.hazardousMaterials,
    goodType: source.goodType.toString() as GoodType,
    truckType: source.truckType.toString() as TruckType,
    loadingDateType: isLoadingDateType(loadingDateType) ? loadingDateType : null,
    fromZones: source.fromZones,
    fromCountry: source.fromCountry,
    toZones: source.toZones,
    toCountry: source.toCountry,
    minWeight: source.minWeight,
    maxWeight: source.maxWeight,
    minLength: source.minLength,
    maxLength: source.maxLength,
    maxVolume: source.maxVolume,
    exchanges: source.exchanges,
    sound: isSound(source.sound) ? source.sound : null,
    sendByEmail: source.sendByEmail,
  };
}
