import { AbortOptions } from '@/shared/dataProviders/common';
import { B2PWebError } from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { Enum } from '@/shared/dataProviders/enum';
import { B2PWebEnumResponse } from '@/shared/dataProviders/enum/b2pweb';

export class PartnerEnumDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchMyCommunities(options?: AbortOptions): Promise<Enum<string, string>[]> {
    const response = await this.client.get<B2PWebEnumResponse<string, string>, B2PWebError>('/enum/my/communities', {
      signal: options?.signal,
    });

    if (!response.isOk) {
      throw new Error(response.data.message);
    }

    return Object.values(response.data);
  }
}
