import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';

export default class GroupSelectionHandler implements EventHandler {
  constructor() {
    this.onDoubleTap = this.onDoubleTap.bind(this);
  }

  registerHook(hammer: HammerManager): void {
    hammer.on('double-tap', this.onDoubleTap);
  }

  unregisterHook(hammer: HammerManager): void {
    hammer.off('double-tap', this.onDoubleTap);
  }

  private onDoubleTap(event: HammerInput): void {
    event.target.closest('.state')?.dispatchEvent(new CustomEvent('group-click', { bubbles: true }));
  }
}
