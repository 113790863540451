export enum Locale {
  English = 'en',
  French = 'fr',
  Italian = 'it',
  Portuguese = 'pt',
  Spanish = 'es',
}

export const DEFAULT_LOCALE = Locale.French;

export function parseLocale(normalizedLocale: string, defaultLocale = DEFAULT_LOCALE): Locale {
  return Object.values(Locale).indexOf(normalizedLocale.substring(0, 2) as Locale) > -1
    ? (normalizedLocale.substring(0, 2) as Locale)
    : defaultLocale;
}
