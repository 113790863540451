export function calculateDistance(from: Coordinates, to: Coordinates) {
  const R = 6371e3;

  const p1 = (from.latitude * Math.PI) / 180;
  const p2 = (to.latitude * Math.PI) / 180;

  const deltaLon = to.longitude - from.longitude;
  const deltaLambda = (deltaLon * Math.PI) / 180;

  return Math.round(
    (Math.acos(Math.sin(p1) * Math.sin(p2) + Math.cos(p1) * Math.cos(p2) * Math.cos(deltaLambda)) * R) / 1000
  );
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}
