import { DateTime } from 'luxon';

import { formatLogoUrl } from '@/shared/dataProviders/common/b2pweb';
import {
  ApiCommunity,
  ApiCommunityPartner,
  ApiCommunityRequest,
  ApiCustomerCommunities,
  ApiMyPartnerCommunity,
  ApiPartnerCommunity,
} from '@/shared/dataProviders/partner/api';

import {
  Community,
  CommunityPartner,
  CommunityRequest,
  CustomerCommunities,
  CustomerCommunity,
  MyPartnerCommunity,
  PartnerCommunity,
  Score,
} from '.';

export function mapToCommunities(items: ApiCommunity[]): Community[] {
  return items.map((item) => ({ ...item }));
}

export function mapToCommunityPartner(source: ApiCommunityPartner): CommunityPartner {
  return {
    _id: `${source.id}_${source.customer.id}`,
    id: source.id,
    label: source.label,
    enabled: source.enabled,
    createdAt: DateTime.fromISO(source.createdAt),
    updatedAt: source.updatedAt ? DateTime.fromISO(source.updatedAt) : null,
    partner: {
      ...source.customer,
      dateInsert: source.customer.dateInsert ? DateTime.fromISO(source.customer.dateInsert) : null,
      logo: source.customer.logo ? formatLogoUrl(source.customer.logo) : null,
    },
  };
}

export function mapToPartnerCommunity(source: ApiPartnerCommunity): PartnerCommunity {
  return {
    id: source.id,
    label: source.label,
    enabled: source.enabled,
    createdAt: DateTime.fromISO(source.createdAt),
    owner: {
      ...source.customer,
      dateInsert: source.customer.dateInsert ? DateTime.fromISO(source.customer.dateInsert) : null,
      logo: source.customer.logo ? formatLogoUrl(source.customer.logo) : null,
    },
  };
}

export function mapToCustomerCommunities(source: ApiCustomerCommunities): CustomerCommunities {
  return {
    isCommunityManager: source.isCommunityManager,
    hasPendingRequest: source.hasPendingRequest,
    items: source.items.map<CustomerCommunity>((customer) => ({ ...customer })),
  };
}

export function mapToCommunityRequest(source: ApiCommunityRequest): CommunityRequest {
  return {
    _id: source.sender.id,
    sender: {
      ...source.sender,
      score: source.sender.score ? (source.sender.score.toString() as Score) : null,
      dateInsert: source.sender.dateInsert ? DateTime.fromISO(source.sender.dateInsert) : null,
      logo: source.sender.logo ? formatLogoUrl(source.sender.logo) : null,
    },
  };
}

export function mapToMyPartnerCommunityCollection(items: ApiMyPartnerCommunity[]): MyPartnerCommunity[] {
  return items.map((item) => ({ ...item }));
}
