export interface ApiExactLocation {
  houseNumber: string | null;
  street: string | null;
  city: string | null;
  zipCode: string | null;
  zone: string | null;
  country: string | null;
  latitude: number | null;
  longitude: number | null;
}

export interface ExactLocation {
  houseNumber: string | null;
  street: string;
  city: string;
  zipCode: string;
  zone: string;
  country: string;
  latitude: number;
  longitude: number;
}

export function fromApi(data: ApiExactLocation): ExactLocation | null {
  return isValid(data)
    ? {
        houseNumber: data.houseNumber,
        street: data.street,
        city: data.city,
        zipCode: data.zipCode,
        zone: data.zone,
        country: data.country,
        latitude: data.latitude,
        longitude: data.longitude,
      }
    : null;
}

function isValid(data: ApiExactLocation): data is ExactLocation {
  return (
    data.street !== null &&
    data.city !== null &&
    data.zipCode !== null &&
    data.zone !== null &&
    data.country !== null &&
    data.latitude !== null &&
    data.longitude !== null
  );
}
