import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';

export default class StateSelectionHandler implements EventHandler {
  constructor() {
    this.onSingleTap = this.onSingleTap.bind(this);
  }

  registerHook(hammer: HammerManager): void {
    hammer.on('single-tap', this.onSingleTap);
  }

  unregisterHook(hammer: HammerManager): void {
    hammer.off('single-tap', this.onSingleTap);
  }

  private onSingleTap(event: HammerInput): void {
    event.target.closest('.state')?.dispatchEvent(new CustomEvent('state-click', { bubbles: true }));
  }
}
