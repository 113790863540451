import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getCurrentTheme, Theme } from '@/shared/theme';
import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';

export const useThemeStore = defineStore('theme', () => {
  const savedTheme: string = localStorageGet<string>(keys.theme, '');
  const current = ref(savedTheme ? savedTheme : getCurrentTheme());

  const isPrivate = computed(() => {
    return current.value !== Theme.B2PWeb && current.value !== Theme.DarkB2PWeb;
  });

  function switchTo(theme: Theme) {
    current.value = theme;
    localStorageSet(keys.theme, current.value);
  }

  return {
    current,
    isPrivate,

    switchTo,
  };
});
