import { AbortOptions, Collection, PaginationOptions, SortingOptions } from '@/shared/dataProviders/common';
import {
  B2PWebCollection,
  B2PWebError,
  handleB2PWebResponse,
  mapToCollection,
} from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { SearchOfferSortingType } from '@/shared/enums/searchOfferSortingType';

import { B2PWebEphemeralSearchOffer, B2PWebEphemeralSearchRequest } from './api';
import { mapToB2PWebEphemeralSearch, mapToEphemeralSearchOffer } from './mappers';
import { EphemeralSearch, EphemeralSearchOffer } from './types';

export class EphemeralSearchDataProvider {
  constructor(private readonly client: RestClient) {}

  async count(searches: EphemeralSearch[], options?: AbortOptions): Promise<number> {
    const { count } = handleB2PWebResponse(
      await this.client.post<{ count: number }, B2PWebEphemeralSearchRequest, B2PWebError>(
        '/ephemeral-search/count',
        {
          searches: searches.map((search) => mapToB2PWebEphemeralSearch(search)),
        },
        { signal: options?.signal }
      )
    );

    return count;
  }

  async search(
    searches: EphemeralSearch[],
    options?: AbortOptions & PaginationOptions & SortingOptions<SearchOfferSortingType>
  ): Promise<Collection<EphemeralSearchOffer>> {
    const collection = handleB2PWebResponse(
      await this.client.post<B2PWebCollection<B2PWebEphemeralSearchOffer>, B2PWebEphemeralSearchRequest, B2PWebError>(
        '/ephemeral-search',
        {
          searches: searches.map((search) => mapToB2PWebEphemeralSearch(search)),
          page: options?.page,
          per_page: options?.perPage,
          sort: options?.sort,
        },
        { signal: options?.signal }
      )
    );

    return mapToCollection(collection, mapToEphemeralSearchOffer);
  }
}
