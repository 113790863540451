import { DateTime } from 'luxon';

import { mapToContact, mapToCustomerContact } from '@/shared/dataProviders/common/b2pweb';
import { GoodType } from '@/shared/enums/goodType';
import { TruckType } from '@/shared/enums/truckType';
import { unserializeZones } from '@/shared/utils';

import {
  FetchThirdPartyVehiclesFilters,
  ThirdPartyVehicle,
  ThirdPartyVehicleForList,
  ThirdPartyVehicleSearch,
  Vehicle,
  VehicleAvailabilityLocation,
  VehicleAvailabilityLocationType,
  VehicleInput,
  VehicleSearchAlert,
  VehicleTemplate,
} from '..';
import {
  B2PWebThirdPartyVehicle,
  B2PWebThirdPartyVehicleForList,
  B2PWebThirdPartyVehicleSearch,
  B2PWebThirdPartyVehicleSearchInput,
  B2PWebVehicle,
  B2PWebVehicleAvailabilityLocation,
  B2PWebVehicleInput,
  B2PWebVehicleSearchAlert,
  B2PWebVehicleTemplate,
} from '.';

export function mapToB2PWebVehicleInput(source: VehicleInput): B2PWebVehicleInput {
  return {
    name: source.name,
    availabilityDate: source.availabilityDate.toISO({ suppressMilliseconds: true }),
    hazardousMaterials: source.hazardousMaterials,
    goodType: Number(source.goodType),
    truckType: Number(source.truckType),
    minWeight: source.minWeight,
    weight: source.maxWeight,
    minLength: source.minLength,
    length: source.maxLength,
    volume: source.maxVolume,
    nbPallets: source.palletCount,
    from:
      source.departure.type === VehicleAvailabilityLocationType.City
        ? {
            type: source.departure.type,
            country: source.departure.country,
            city: source.departure.city,
            zone: source.departure.zone,
            zipCode: source.departure.zipCode,
            latitude: source.departure.latitude,
            longitude: source.departure.longitude,
            radius: source.departure.radius,
          }
        : {
            type: source.departure.type,
            country: source.departure.country,
            zones: source.departure.zones,
          },
    to:
      source.arrival.type === VehicleAvailabilityLocationType.City
        ? {
            type: source.arrival.type,
            country: source.arrival.country,
            city: source.arrival.city,
            zone: source.arrival.zone,
            zipCode: source.arrival.zipCode,
            latitude: source.arrival.latitude,
            longitude: source.arrival.longitude,
            radius: source.arrival.radius,
          }
        : {
            type: source.arrival.type,
            country: source.arrival.country,
            zones: source.arrival.zones,
          },
    comment: source.comment,
    exchanges: source.exchanges ?? [],
    saveAsTemplate: source.saveAsTemplate,
  };
}

export function mapToVehicle(source: B2PWebVehicle): Vehicle {
  return {
    id: source.id,
    name: source.name ?? undefined,
    availabilityDate: DateTime.fromISO(source.availability.realDateStart, { setZone: true }),
    hazardousMaterials: source.hazardousMaterials,
    goodType: source.goodType.toString() as GoodType,
    truckType: source.truckType.toString() as TruckType,
    minWeight: source.minWeight || undefined,
    maxWeight: source.weight || undefined,
    minLength: source.minLength || undefined,
    maxLength: source.length || undefined,
    maxVolume: source.volume || undefined,
    palletCount: source.nbPallets || undefined,
    departure: mapToVehicleAvailabilityLocation(source.from),
    arrival: mapToVehicleAvailabilityLocation(source.to),
    exchanges: source.exchanges,
    comment: source.comment ?? undefined,
    creationDate: DateTime.fromISO(source.createdAt),
  };
}

export function mapToVehicleAvailabilityLocation(
  source: B2PWebVehicleAvailabilityLocation
): VehicleAvailabilityLocation {
  return source.type === 'city'
    ? {
        type: VehicleAvailabilityLocationType.City,
        country: source.country,
        city: source.city,
        zone: source.zone,
        zipCode: source.zipCode,
        latitude: source.latitude,
        longitude: source.longitude,
        radius: source.radius ?? 0,
      }
    : {
        type: VehicleAvailabilityLocationType.Zone,
        country: source.country,
        zones: unserializeZones(source.zones.join(','), source.country),
      };
}

export function mapToThirdPartyVehicle(source: B2PWebThirdPartyVehicle): ThirdPartyVehicle {
  return {
    ...mapToThirdPartyVehicleForList(source),
    contact: mapToContact(source.contact),
  };
}

export function mapToThirdPartyVehicleForList(source: B2PWebThirdPartyVehicleForList): ThirdPartyVehicleForList {
  return {
    ...mapToVehicle(source),
    customerContact: mapToCustomerContact(source.customerContact),
  };
}

export function mapToB2PWebVehicleSearchInput(
  name: string,
  filters: FetchThirdPartyVehiclesFilters
): B2PWebThirdPartyVehicleSearchInput {
  return {
    name,
    availabilityDate: filters.availabilityDate?.toISO({ suppressMilliseconds: true }),
    goodType: filters.goodType,
    truckTypes: filters.truckTypes,
    from: {
      country: filters.departureCountry,
      zones: filters.departureZones ? [filters.departureZones] : undefined,
    },
    to: {
      country: filters.arrivalCountry,
      zones: filters.arrivalZones ? [filters.arrivalZones] : undefined,
    },
    weight: filters.weight ? parseFloat(filters.weight) : undefined,
    length: filters.length ? parseFloat(filters.length) : undefined,
    volume: filters.volume ? parseFloat(filters.volume) : undefined,
    exchanges: filters.exchanges,
  };
}

export function mapToVehicleSearch(source: B2PWebThirdPartyVehicleSearch): ThirdPartyVehicleSearch {
  return {
    id: source.id,
    name: source.name,
    availabilityDate: source.availability.dateStart
      ? DateTime.fromISO(source.availability.dateStart, { setZone: true })
      : undefined,
    goodType: source.goodType?.toString() as GoodType,
    truckTypes: source.truckTypes?.map((truckType) => truckType.toString()) as TruckType[],
    weight: source.weight ?? undefined,
    length: source.length ?? undefined,
    volume: source.volume ?? undefined,
    departure:
      source.from.country || source.from.zones?.length
        ? {
            country: source.from.country ?? undefined,
            zones: source.from.zones
              ? unserializeZones(source.from.zones.join(','), source.from.country ?? undefined)
              : undefined,
          }
        : undefined,
    arrival:
      source.to.country || source.to.zones?.length
        ? {
            country: source.to.country ?? undefined,
            zones: source.to.zones
              ? unserializeZones(source.to.zones.join(','), source.to.country ?? undefined)
              : undefined,
          }
        : undefined,
    exchanges: source.exchanges ?? undefined,
  };
}

export function mapValidationErrorField(name: string): string {
  switch (name) {
    case 'weight':
      return 'maxWeight';
    case 'length':
      return 'maxLength';
    case 'volume':
      return 'maxVolume';
    case 'nbPallets':
      return 'palletCount';

    case 'from[type]':
      return 'departureType';
    case 'from[country]':
      return 'departureCountry';
    case 'from[city]':
      return 'departureCity';
    case 'from[zone]':
      return 'departureZone';
    case 'from[zipCode]':
      return 'departureZipCode';
    case 'from[radius]':
      return 'departureRadius';
    case 'from[zones]':
      return 'departureZones';

    case 'to[type]':
      return 'arrivalType';
    case 'to[country]':
      return 'arrivalCountry';
    case 'to[city]':
      return 'arrivalCity';
    case 'to[zone]':
      return 'arrivalZone';
    case 'to[zipCode]':
      return 'arrivalZipCode';
    case 'to[radius]':
      return 'arrivalRadius';
    case 'to[zones]':
      return 'arrivalZones';

    default:
      return name;
  }
}

export function mapToVehicleTemplate(source: B2PWebVehicleTemplate): VehicleTemplate {
  return {
    id: source.id,
    name: source.name ?? undefined,
    hazardousMaterials: source.hazardousMaterials,
    goodType: source.goodType.toString() as GoodType,
    truckType: source.truckType.toString() as TruckType,
    minWeight: source.minWeight || undefined,
    maxWeight: source.weight || undefined,
    minLength: source.minLength || undefined,
    maxLength: source.length || undefined,
    maxVolume: source.volume || undefined,
    palletCount: source.nbPallets || undefined,
    departure: mapToVehicleAvailabilityLocation(source.from),
    arrival: mapToVehicleAvailabilityLocation(source.to),
    exchanges: source.exchanges,
    comment: source.comment ?? undefined,
    creationDate: DateTime.fromISO(source.createdAt),
  };
}

export function mapToVehicleSearchAlert(source: B2PWebVehicleSearchAlert): VehicleSearchAlert {
  return {
    id: source.id,
    newVehicleCount: source.newVehiclesCount,
  };
}
