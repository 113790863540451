import * as api from '@/shared/api/admin';
import { composeApiParameter, composeParameter } from '@/shared/dataProviders/admin/mappers';
import {
  Parameter,
  ParameterType,
  TimeSettings,
  UserInformation,
  UserParameter,
  UserParameterType,
  UserSettings,
} from '@/shared/dataProviders/admin/types';

export async function getParameter<T extends ParameterType>(id: T): Promise<Parameter<T> | undefined> {
  try {
    return composeParameter<T>(await api.getParameter(id));
  } catch (error) {
    if (error instanceof api.ParameterNotFoundError) {
      return;
    }
  }
}

export async function setParameter(input: Parameter<ParameterType>): Promise<void> {
  await api.setParameter(composeApiParameter(input));
}

export async function getUserInformation(signal?: AbortSignal): Promise<UserInformation> {
  return api.getUserInformation(signal);
}

export async function replaceUserLanguages(values: string[]): Promise<void> {
  await api.replaceUserLanguages(values);
}

export async function getUserParameter(
  id: UserParameterType,
  signal?: AbortSignal
): Promise<UserParameter | undefined> {
  return await api.getUserParameter(id, signal);
}

export async function replaceUserParameter(id: UserParameterType, value: string): Promise<void> {
  await api.replaceUserParameter(id, value);
}

export async function getUserSettings(): Promise<UserSettings> {
  return await api.getUserSettings();
}

export async function getTimeSettings(): Promise<TimeSettings> {
  return await api.getTimeSettings();
}
