export type CustomListColor =
  | 'list-dark-blue'
  | 'list-dark-red'
  | 'list-blue'
  | 'list-brown'
  | 'list-gray'
  | 'list-green'
  | 'list-purple';

export const availableCustomListColors: CustomListColor[] = [
  'list-dark-blue',
  'list-dark-red',
  'list-blue',
  'list-brown',
  'list-gray',
  'list-green',
  'list-purple',
];
