import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';
import { Vector } from '@/shared/components/state-selector/utils/math';

interface DragOptions {
  onDragStart?: () => void;
  onDrag: (vector: Vector) => void;
  onDragEnd?: () => void;
}

export default class DragHandler implements EventHandler {
  private previousDeltaX!: number;
  private previousDeltaY!: number;

  constructor(private options: DragOptions) {
    this.onPanStart = this.onPanStart.bind(this);
    this.onPanMove = this.onPanMove.bind(this);
    this.onPanEnd = this.onPanEnd.bind(this);
  }

  registerHook(hammer: HammerManager): void {
    hammer.on('panstart', this.onPanStart);
    hammer.on('panmove', this.onPanMove);
    hammer.on('panend', this.onPanEnd);
  }

  unregisterHook(hammer: HammerManager): void {
    hammer.off('panstart', this.onPanStart);
    hammer.off('panmove', this.onPanMove);
    hammer.off('panend', this.onPanEnd);
  }

  private onPanStart(event: HammerInput): void {
    event.preventDefault();

    this.previousDeltaX = event.deltaX;
    this.previousDeltaY = event.deltaY;

    this.options.onDragStart?.();
  }

  private onPanMove(event: HammerInput): void {
    event.preventDefault();

    this.options.onDrag({
      dx: this.previousDeltaX - event.deltaX,
      dy: this.previousDeltaY - event.deltaY,
    });

    this.previousDeltaX = event.deltaX;
    this.previousDeltaY = event.deltaY;
  }

  private onPanEnd(event: HammerInput): void {
    event.preventDefault();

    this.options.onDragEnd?.();
  }
}
