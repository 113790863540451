import { Ref, ref } from 'vue';

import { acl, Permission } from '@/services/acl';
import { Acl } from '@/shared/acl';
import config from '@/shared/config';

export enum ExternalService {
  FlowMap = 's2pweb.flowMap',
  WarehousesMap = 's2pweb.warehousesMap',
  TraceabilityPass = 's3pweb.traceabilityPass',
  PalletSimulator = 'palbank.palletSimulator',
  Help = 'b2pweb.help',
}

type ServicePermission = {
  [key in ExternalService]?: Permission;
};

const servicePermission: ServicePermission = {
  [ExternalService.FlowMap]: Permission.FlowMapAccess,
  [ExternalService.WarehousesMap]: Permission.DirectorySearch,
  [ExternalService.TraceabilityPass]: Permission.S3PWebTraceabilityPassAccess,
};

export class ExternalServiceCollection {
  private services: Ref<Set<ExternalService>>;
  private acl: Acl;

  constructor(acl: Acl) {
    this.services = ref(new Set());
    this.acl = acl;
  }

  configure(services: ExternalService[]): void {
    this.services.value = new Set(services);
  }

  isEnabled(service: ExternalService): boolean {
    return this.isConfigured(service) && this.isAuthorized(service);
  }

  isConfigured(service: ExternalService): boolean {
    return this.services.value.has(service);
  }

  isAuthorized(service: ExternalService): boolean {
    const permission = servicePermission[service] ?? false;

    return !permission || this.acl.can(permission);
  }
}

export function getConfiguredServices(): ExternalService[] {
  const services: ExternalService[] = [];
  if (config.urls.flowMapJs && config.urls.flowMapEsmJs) {
    services.push(ExternalService.FlowMap);
  }
  if (config.urls.warehousesMapJs && config.urls.warehousesMapEsmJs) {
    services.push(ExternalService.WarehousesMap);
  }
  if (config.urls.s3pwebTraceabilityPass) {
    services.push(ExternalService.TraceabilityPass);
  }
  if (config.palbank.url) {
    services.push(ExternalService.PalletSimulator);
  }
  if (config.urls.help) {
    services.push(ExternalService.Help);
  }

  return services;
}

export const externalService = new ExternalServiceCollection(acl);
externalService.configure(getConfiguredServices());
