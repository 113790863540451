import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import eventBus from '@/event-bus';
import { Search } from '@/shared/types';
import { get as getFromLocalStorage, keys, set as setToLocalStorage } from '@/store/localStorage';

export const useSearchNotificationSettings = defineStore('search-notification-settings', () => {
  const disabledSearches = ref(
    new Set<Search['id']>(getFromLocalStorage(keys.notificationSettingsDisabledSearches, []))
  );

  function disable(searchIdToDisable: Search['id']) {
    disabledSearches.value.add(searchIdToDisable);
  }

  function enable(searchIdToEnable: Search['id']) {
    disabledSearches.value.delete(searchIdToEnable);
  }

  eventBus.$on('search.searches-received', (event: { searches: Search[] }) => {
    const searchIdsToRemove = Array.from(disabledSearches.value.values()).filter(
      (searchId) => event.searches.find((search) => search.id === searchId) === undefined
    );

    for (const searchId of searchIdsToRemove) {
      disabledSearches.value.delete(searchId);
    }
  });

  eventBus.$on('search.search-deleted', (event: { searchId: Search['id'] }) => {
    disabledSearches.value.delete(event.searchId);
  });

  eventBus.$on('search.search-updated', (event: { previousSearchId: Search['id']; newSearchId: Search['id'] }) => {
    if (!disabledSearches.value.has(event.previousSearchId)) {
      return;
    }

    disabledSearches.value.add(event.newSearchId);
    disabledSearches.value.delete(event.previousSearchId);
  });

  watch(
    disabledSearches,
    () => {
      setToLocalStorage(keys.notificationSettingsDisabledSearches, Array.from(disabledSearches.value));
    },
    { deep: true }
  );

  return {
    disabledSearches,

    disable,
    enable,
  };
});
