import { computed, reactive, unref } from 'vue';

import { MaybeRef } from '@/shared/composables/utils/types';
import { Enum } from '@/shared/dataProviders/enum';
import { sortOptionListByText, sortOptionListByValue } from '@/shared/filters';
import { SelectOption } from '@/shared/types';

export interface EnumOptions {
  sort: {
    by: 'text' | 'value';
    options?: Intl.CollatorOptions;
  };
}

export function useEnum<EnumType extends Enum<Key, string>, Key extends boolean | string | number>(
  items: MaybeRef<EnumType[]>,
  config?: EnumOptions
) {
  const sortOptionsList =
    config?.sort.by === 'text'
      ? sortOptionListByText
      : config?.sort.by === 'value'
        ? sortOptionListByValue
        : (items: SelectOption<Key>[]) => items;

  const options = computed(() => sortOptionsList(mapEnumToSelectOption(unref(items)), config?.sort.options));

  const filter = (predicate: (item: EnumType) => boolean) =>
    useEnum(
      computed(() => unref(items).filter(predicate)),
      config
    );
  const keys = () => unref(items).map((item) => item.key);

  const labelOf = (key: Key) => unref(items).find((item) => item.key === key)?.value ?? '';

  return reactive({
    items,
    options,

    filter,
    keys,

    labelOf,
  });
}

function mapEnumToSelectOption<T extends boolean | number | string>(items: Enum<T, string>[]): SelectOption<T>[] {
  return items.map((item) => ({
    text: item.value,
    value: item.key,
  }));
}
