import { DateTime } from 'luxon';

import axios from '../axios';
import { ApiCollectionResponse, Collection, PaginationOptions, SortingOptions } from '../utils';
import { ApiCustomerContact, CustomerContact, fromApi as fromCustomerContactApi } from './contact-customer';
import { ApiLocation, fromApi as fromLocationApi, Location } from './location';

export const enum ForbiddingLevel {
  NONE = 0,
  UNADVISED = 1,
  FORBIDDEN = 2,
}

const CONSULTED_STATE = 6;

export interface GetSearchOffersOptions extends PaginationOptions, SortingOptions {
  shouldPrioritizeExchanges?: boolean;
}

export interface ApiListSearchOffer {
  id: string;
  customerContact: ApiCustomerContact;
  forbiddingLevel: ForbiddingLevel;
  goodType: string;
  truckTypes: string[];
  lading: ApiLocation;
  delivery: ApiLocation;
  hazardousMaterials: boolean;
  weight: number | null;
  length: number | null;
  volume: number | null;
  distance: number | null;
  exchangeType: number;
  state: number;
  createdAt: string;
  updatedAt: string;
}

export interface SearchOffer {
  id: string;
  customer: CustomerContact;
  forbiddingLevel: ForbiddingLevel;
  goodType: string;
  truckTypes: string[];
  lading: Location;
  delivery: Location;
  hazardousMaterials: boolean;
  weight: number | null;
  length: number | null;
  volume: number | null;
  distance: number | null;
  exchangeType: number;
  isConsulted: boolean;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export function fromApi(data: ApiListSearchOffer): SearchOffer {
  return {
    id: data.id,
    customer: fromCustomerContactApi(data.customerContact),
    forbiddingLevel: data.forbiddingLevel,
    goodType: String(data.goodType),
    truckTypes: data.truckTypes,
    lading: fromLocationApi(data.lading),
    delivery: fromLocationApi(data.delivery),
    hazardousMaterials: data.hazardousMaterials,
    weight: data.weight,
    length: data.length,
    volume: data.volume,
    distance: data.distance,
    exchangeType: data.exchangeType,
    isConsulted: data.state === CONSULTED_STATE,
    createdAt: DateTime.fromISO(data.createdAt),
    updatedAt: DateTime.fromISO(data.updatedAt),
  };
}

export async function list(
  searchId: string,
  { page = 1, perPage = 40, shouldPrioritizeExchanges, sort }: GetSearchOffersOptions = {}
): Promise<Collection<SearchOffer>> {
  const { data } = await axios.get<ApiCollectionResponse<ApiListSearchOffer>>(`searches/${searchId}/offers`, {
    params: {
      page,
      per_page: perPage,
      ...(shouldPrioritizeExchanges ? { ['orders[exchangeType]']: 'DESC' } : undefined),
      ...(sort ? { [`orders[${sort.substring(1)}]`]: sort[0] === '+' ? 'ASC' : 'DESC' } : undefined),
    },
  });

  return {
    items: data.items.map(fromApi),
    page: data.pagination.currentPage,
    pageCount: data.pagination.pageCount,
    limit: data.pagination.limit,
    totalCount: data.pagination.totalCount,
  };
}
