import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';
import { Point } from '@/shared/components/state-selector/utils/math';
import throttle from '@/shared/components/state-selector/utils/throttle';

interface ZoomOptions {
  onZoomStart?: () => void;
  onZoom: (point: Point, step: number) => void;
  onZoomEnd?: () => void;
}

export default class ZoomHandler implements EventHandler {
  private enabled = false;
  private point!: Point;
  private step!: number;
  private zoomEndTimer?: number;

  constructor(private options: ZoomOptions) {
    this.onWheel = this.onWheel.bind(this);
    this.dispatchZoomEvent = throttle(this.dispatchZoomEvent.bind(this), 25);
  }

  registerHook(_: HammerManager, root: SVGElement): void {
    root.addEventListener('wheel', this.onWheel);
  }

  unregisterHook(_: HammerManager, root: SVGElement): void {
    root.removeEventListener('wheel', this.onWheel);
  }

  private onWheel(event: WheelEvent): void {
    event.preventDefault();

    if (!event.deltaY) {
      return;
    }

    clearTimeout(this.zoomEndTimer);

    if (!this.enabled) {
      this.enabled = true;

      this.point = { x: event.clientX, y: event.clientY };
      this.step = 0;

      if (this.options.onZoomStart) {
        this.options.onZoomStart();
      }
    }

    this.step += event.deltaY < 0 ? -1 : 1;

    this.dispatchZoomEvent();

    if (this.enabled) {
      this.zoomEndTimer = setTimeout(() => {
        this.enabled = false;

        if (this.options.onZoomEnd) {
          this.options.onZoomEnd();
        }
      }, 150);
    }
  }

  private dispatchZoomEvent(): void {
    const { step } = this;

    this.step = 0;

    this.options.onZoom({ x: this.point.x, y: this.point.y }, step);
  }
}
