import { computed, reactive, ref, toRefs } from 'vue';

import { AbortOptions } from '@/shared/dataProviders/common';
import { Enum } from '@/shared/dataProviders/enum';
import { RefreshOptions } from '@/shared/enums/types';
import { EnumOptions, useEnum } from '@/shared/enums/useEnum';

enum Status {
  Pending = 'pending',
  Loading = 'loading',
}

const cache = ref<Record<string, Enum<string | number, string>[]>>({});

export function useAsyncEnum<EnumType extends Enum<Key, Value>, Key extends string | number, Value extends string>(
  id: string,
  loader: (options?: AbortOptions) => Promise<EnumType[]>,
  config?: EnumOptions
) {
  const items = computed<EnumType[]>(() => (cache.value[id] as EnumType[] | undefined) ?? []);

  const status = ref<Status>(Status.Pending);
  const isPending = computed(() => status.value === Status.Pending);
  const isLoading = computed(() => status.value === Status.Loading);

  return reactive({
    ...toRefs(useEnum(items, config)),

    isPending,
    isLoading,

    load: async (options?: AbortOptions & RefreshOptions) => {
      if (isLoading.value || (cache.value[id] && !options?.refresh)) {
        return;
      }

      try {
        status.value = Status.Loading;

        cache.value[id] = await loader(options);
      } finally {
        status.value = Status.Pending;
      }
    },
  });
}

export function reset() {
  cache.value = {};
}
