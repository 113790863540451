import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';
import { Point } from '@/shared/components/state-selector/utils/math';
import throttle from '@/shared/components/state-selector/utils/throttle';

interface ZoomOptions {
  onZoomStart?: () => void;
  onZoom: (point: Point, step: number) => void;
  onZoomEnd?: () => void;
}

export default class PinchToZoomHandler implements EventHandler {
  private point!: Point;
  private step!: number;

  constructor(private options: ZoomOptions) {
    this.dispatchZoomEvent = throttle(this.dispatchZoomEvent.bind(this), 25);
    this.onPinchStart = this.onPinchStart.bind(this);
    this.onPinchIn = this.onPinchIn.bind(this);
    this.onPinchOut = this.onPinchOut.bind(this);
    this.onPinchEnd = this.onPinchEnd.bind(this);
  }

  registerHook(hammer: HammerManager): void {
    hammer.on('pinchstart', this.onPinchStart);
    hammer.on('pinchin', this.onPinchIn);
    hammer.on('pinchout', this.onPinchOut);
    hammer.on('pinchend', this.onPinchEnd);
  }

  unregisterHook(hammer: HammerManager): void {
    hammer.off('pinchstart', this.onPinchStart);
    hammer.off('pinchin', this.onPinchIn);
    hammer.off('pinchout', this.onPinchOut);
    hammer.off('pinchend', this.onPinchEnd);
  }

  private onPinchStart() {
    this.step = 0;
    this.options.onZoomStart?.();
  }

  private onPinchIn(event: HammerInput) {
    this.point = { x: event.center.x, y: event.center.y };
    this.step += 0.25;
    this.dispatchZoomEvent();
  }

  private onPinchOut(event: HammerInput) {
    this.point = { x: event.center.x, y: event.center.y };
    this.step -= 0.25;
    this.dispatchZoomEvent();
  }

  private dispatchZoomEvent() {
    this.options.onZoom({ x: this.point.x, y: this.point.y }, this.step);
    this.step = 0;
  }

  private onPinchEnd() {
    this.options.onZoomEnd?.();
  }
}
