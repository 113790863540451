import axios from 'axios';

import { setupAuthentication } from '@/shared/api/util/interceptors/setupAuthentication';
import { setupUserLanguage } from '@/shared/api/util/interceptors/setupUserLanguage';
import config from '@/shared/config';
import { AxiosRestClient } from '@/shared/dataProviders/common/AxiosRestClient';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { CommunityDataProvider } from '@/shared/dataProviders/partner/CommunityDataProvider';
import { CommunityPartnerDataProvider } from '@/shared/dataProviders/partner/CommunityPartnerDataProvider';
import { CommunityRequestDataProvider } from '@/shared/dataProviders/partner/CommunityRequestDataProvider';
import { PartnerCommunityDataProvider } from '@/shared/dataProviders/partner/PartnerCommunityDataProvider';
import { PartnerEnumDataProvider } from '@/shared/dataProviders/partner/PartnerEnumDataProvider';

export * from './types';

let communityDataProvider: CommunityDataProvider;
export function useCommunityDataProvider(): CommunityDataProvider {
  if (!communityDataProvider) {
    communityDataProvider = new CommunityDataProvider(useRestClient());
  }
  return communityDataProvider;
}

let communityPartnerDataProvider: CommunityPartnerDataProvider;
export function useCommunityPartnerDataProvider(): CommunityPartnerDataProvider {
  if (!communityPartnerDataProvider) {
    communityPartnerDataProvider = new CommunityPartnerDataProvider(useRestClient());
  }
  return communityPartnerDataProvider;
}

let partnerCommunityDataProvider: PartnerCommunityDataProvider;
export function usePartnerCommunityDataProvider(): PartnerCommunityDataProvider {
  if (!partnerCommunityDataProvider) {
    partnerCommunityDataProvider = new PartnerCommunityDataProvider(useRestClient());
  }
  return partnerCommunityDataProvider;
}

let communityRequestDataProvider: CommunityRequestDataProvider;
export function useCommunityRequestDataProvider(): CommunityRequestDataProvider {
  if (!communityRequestDataProvider) {
    communityRequestDataProvider = new CommunityRequestDataProvider(useRestClient());
  }
  return communityRequestDataProvider;
}

let enumDataProvider: PartnerEnumDataProvider;
export function usePartnerEnumDataProvider(): PartnerEnumDataProvider {
  if (!enumDataProvider) {
    enumDataProvider = new PartnerEnumDataProvider(useRestClient());
  }
  return enumDataProvider;
}

let client: RestClient;
function useRestClient(): RestClient {
  if (!client) {
    const axiosInstance = axios.create({
      baseURL: config.urls.searchApi,
    });

    setupAuthentication(axiosInstance);
    setupUserLanguage(axiosInstance);

    client = new AxiosRestClient(axiosInstance);
  }
  return client;
}
