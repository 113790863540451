import { DateTime } from 'luxon';
import { DefaultDateTimeFormatSchema } from 'vue-i18n';

import translator from '@/shared/locale';

// TODO Refactor
export function formatDate(
  date: DateTime,
  { format = 'dd/MM HH:mm', shortenFormat = 'dd/MM' }: { format?: string; shortenFormat?: string } = {}
): string {
  const datetimeFormats: Record<string, DefaultDateTimeFormatSchema> = translator().datetimeFormats.value;

  if (shortenFormat && date.toFormat('HH:mm') === '00:00') {
    return date
      .setLocale(translator().locale.value)
      .toLocaleString(datetimeFormats[translator().locale.value][shortenFormat]);
  }

  return date.setLocale(translator().locale.value).toLocaleString(datetimeFormats[translator().locale.value][format]);
}
