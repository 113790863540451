const searchRoutes = {
  list: 'search-list',
  create: 'search-create',
  edit: 'search-edit',
  history: {
    consulted: 'search-history-consulted',
    consultedOfferDisplay: 'search-history-consulted-offer-display',
    saved: 'search-history-saved',
    savedOfferDisplay: 'search-history-saved-offer-display',
  },
  offerDisplay: 'search-offer-display',
  partnerList: 'search-partner-list',
};

const offerRoutes = {
  list: 'offer-list',
  supplement: 'offer-supplement',
  create: 'offer-create',
  display: 'offer-display',
  edit: 'offer-edit',
  duplicate: 'offer-duplicate',
  deleted: {
    list: 'offer-deleted-list',
    display: 'offer-deleted-display',
    duplicate: 'offer-deleted-duplicate',
  },
};

const vehicleRoutes = {
  management: {
    create: 'vehicle-management-create',
    edit: 'vehicle-management-edit',
    duplicate: 'vehicle-management-duplicate',
    list: 'vehicle-management-list',
    display: 'vehicle-management-display',
  },
  search: {
    list: 'vehicle-search-list',
    display: 'vehicle-search-display',
    saved: 'vehicle-search-saved',
    savedDisplay: 'vehicle-search-saved-display',
  },
};

const directoryRoutes = {
  list: 'toolbox-directory',
  listDisplay: 'toolbox-directory-display',
  my: 'directory-my',
};

const toolboxRoutes = {
  directory: directoryRoutes.list,
  flowMap: 'toolbox-flow-map',
  routeCalculator: 'toolbox-route-calculator',
  warehousesMap: 'toolbox-warehouses-map',
};

const partnerRoutes = {
  myCommunities: 'partner-my-communities',
  myPartners: 'partner-my-partners',
  myPartnersDisplay: 'partner-my-partners-display',
  requests: 'partner-requests',
  requestsDisplay: 'partner-requests-display',
  search: 'partner-search',
  searchDisplay: 'partner-search-display',
};

const routes = {
  auth: 'auth',
  home: 'home',
  search: searchRoutes,
  offer: offerRoutes,
  vehicle: vehicleRoutes,
  toolbox: toolboxRoutes,
  settings: 'settings',
  directory: directoryRoutes,
  partner: partnerRoutes,
};

export default routes;
