import { fetchPostProfiles } from '@/shared/api/offer/read';
import { RefreshOptions } from '@/shared/enums/types';

let profiles: Record<string, string>;

const defaults: PostProfileType[] = [];

export type PostProfileType = keyof typeof profiles;

export function isPostProfileType(code: string): code is PostProfileType {
  if (!profiles) {
    throw 'Unable to type guard post profile code if enum is not loaded';
  }

  return !!code && !!profiles[code];
}

export function isDefaultProfile(code: string): boolean {
  return isPostProfileType(code) && defaults.includes(code);
}

export async function list(options?: RefreshOptions): Promise<Record<string, string>> {
  if (!profiles || options?.refresh) {
    const values: Record<string, string> = {};

    for (const { key, value, isDefault } of await fetchPostProfiles()) {
      values[key as PostProfileType] = value;

      if (isDefault) {
        defaults.push(key);
      }
    }

    profiles = values;
  }

  return profiles;
}
