import EventHandler from '@/shared/components/state-selector/event/handlers/EventHandler';
import { Point } from '@/shared/components/state-selector/utils/math';

enum TimerStatus {
  PENDING,
  STARTED,
  STOPPED,
  TIMED_OUT,
}

class Timer {
  private startTime!: number;
  private animationId?: number;
  private status = TimerStatus.PENDING;

  constructor(
    private onProgress: (progress: number) => void,
    private duration: number
  ) {
    this.update = this.update.bind(this);
  }

  start(): void {
    if (this.status !== TimerStatus.PENDING) {
      return;
    }

    this.animationId = requestAnimationFrame(this.update);
    this.status = TimerStatus.STARTED;
  }

  stop(): void {
    if (this.status !== TimerStatus.STARTED) {
      return;
    }

    if (this.animationId) {
      cancelAnimationFrame(this.animationId);

      this.animationId = undefined;
    }

    this.status = TimerStatus.STOPPED;
  }

  isTimedOut(): boolean {
    return this.status === TimerStatus.TIMED_OUT;
  }

  private update(timestamp: number): void {
    this.animationId = undefined;

    if (!this.startTime) {
      this.startTime = timestamp;
    }

    const progress = Math.min(timestamp - this.startTime, this.duration) / this.duration;

    this.onProgress(progress);

    if (progress < 1) {
      this.animationId = requestAnimationFrame(this.update);
    } else {
      this.status = TimerStatus.TIMED_OUT;
    }
  }
}

interface CountryHoldOptions {
  onProgress: (point: Point, progress: number) => void;
  onEnd: () => void;
}

export default class CountryHoldHandler implements EventHandler {
  private target?: SVGElement;
  private progressTimer?: Timer;

  constructor(private options: CountryHoldOptions) {
    this.onPan = this.onPan.bind(this);
    this.onPress = this.onPress.bind(this);
    this.onPressUp = this.onPressUp.bind(this);
  }

  registerHook(hammer: HammerManager): void {
    hammer.on('pan', this.onPan);
    hammer.on('press', this.onPress);
    hammer.on('pressup', this.onPressUp);
  }

  unregisterHook(hammer: HammerManager): void {
    hammer.off('pan', this.onPan);
    hammer.off('press', this.onPress);
    hammer.off('pressup', this.onPressUp);

    this.clear();
  }

  private onPress(event: HammerInput): void {
    this.target = event.target.closest('.country') as SVGElement;

    if (!this.target) {
      return;
    }

    this.progressTimer = new Timer((progress) => {
      this.options.onProgress({ x: event.center.x, y: event.center.y }, progress);
    }, 400);

    this.progressTimer.start();
  }

  private onPressUp(): void {
    if (this.progressTimer?.isTimedOut()) {
      this.target?.dispatchEvent(new CustomEvent('country-hold', { bubbles: true }));
    }

    this.clear();
  }

  private onPan(): void {
    this.clear();
  }

  private clear(): void {
    this.progressTimer?.stop();
    this.progressTimer = undefined;

    this.options.onEnd();
  }
}
