export interface ApiCollection<ItemType> {
  readonly items: ItemType[];
  readonly pagination: {
    readonly limit: number;
    readonly pageCount: number;
    readonly currentPage: number;
    readonly totalCount: number;
  };
}

export type ApiCurrency = 'EUR';

export interface ApiPrice {
  value: number;
  currency: ApiCurrency;
}

export interface ApiLocation {
  city: string;
  zipCode: string;
  zone: string;
  country: string;
  latitude: number;
  longitude: number;
}

export interface ApiExactLocation extends ApiLocation {
  isPublic: boolean;
  houseNumber: string;
  street: string;
}

export interface ApiLading extends ApiLocation {
  dateStart: string;
  dateEnd: string | null;
}

export interface ApiDelivery extends ApiLocation {
  dateStart: string | null;
  dateEnd: string | null;
}

export interface ApiState {
  id: string;
  code: string;
}

export interface ApiContact {
  id: string;
  civility: string | null;
  name?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  email?: string;
  languages?: string[];
}

export interface ApiCustomerContact {
  id: string;
  name: string;
  country: string;
  zone: string;
  logo: string | null;
  dateInsert: string | null;
}

export interface ApiOfferCore {
  id: string;
  userId: string;
  partnerExchanges: string[];
  partnerToProfileSwitch: number | null;
  price: ApiPrice;
  partnerPrice: ApiPrice;
  contact: ApiContact;
  customerContact: ApiCustomerContact;
  goodType: string;
  truckTypes: string[];
  lading: ApiLading;
  delivery: ApiDelivery;
  ladingExactLocation: ApiExactLocation;
  deliveryExactLocation: ApiExactLocation;
  hazardousMaterials: boolean;
  weight: number | null;
  length: number | null;
  volume: number | null;
  distance: number | null;
  notes: string | null;
  orderRef: string | null;
  profileId: string;
  lastActionDate: string;
}

export interface ApiDeletedSketchyOffer extends ApiOfferCore {
  deleteCause: string | null;
}

export interface ApiDeletedOffer extends ApiDeletedSketchyOffer {
  states: ApiState[];
}

export interface ApiSketchyOffer extends ApiOfferCore {
  hasError: boolean;
}

export interface ApiOffer extends ApiSketchyOffer {
  states: ApiState[];
}

export interface ApiTemplate {
  id: string;
  userId: string;
  partnerExchanges: string[];
  partnerToProfileSwitch: number | null;
  price: ApiPrice;
  partnerPrice: ApiPrice;
  contact: ApiContact;
  customerContact: ApiCustomerContact;
  goodType: string;
  truckTypes: string[];
  lading: ApiLading;
  delivery: ApiDelivery;
  ladingExactLocation: ApiExactLocation;
  deliveryExactLocation: ApiExactLocation;
  hazardousMaterials: boolean;
  weight: number;
  length: number;
  volume: number | null;
  distance: number | null;
  notes: string | null;
  orderRef: string | null;
  profileId: string;
}

export interface ApiConsultation {
  customerId: string;
  customerSiret: string;
  customerName: string;
  customerAddress1: string;
  customerZipCode: string;
  customerCity: string | null;
  customerCountry: string;
  customerDateInsert: string | null;
  userId: string;
  userName: string | null;
  userPhone: string | null;
  userFax: string | null;
  userEmail: string | null;
  createdAt: string;
}

export interface ApiRealOfferInput {
  readonly orderRef?: string;
  readonly goodType: string;
  readonly truckTypes?: string[];
  readonly hazardousMaterials?: boolean;
  readonly lading: ApiRealOfferLocationInput;
  readonly delivery: ApiRealOfferLocationInput;
  readonly shipment: ApiRealOfferShipmentInput;
  readonly isFull?: boolean;
  readonly returnablePackage?: boolean;
  readonly sender: ApiRealOfferContactInput;
  readonly carrier: ApiRealOfferCarrierInput;
  readonly recipient: ApiRealOfferContactInput;
  readonly length: number;
  readonly weight: number;
  readonly volume?: number;
  readonly price?: number;
  readonly currency: string;
  readonly notes?: string;
}

export interface ApiRealOfferContactInput {
  readonly id?: string;
  readonly name: string;
  readonly phone?: string;
  readonly fax?: string;
  readonly email?: string;
  readonly address?: string;
}

export interface ApiRealOfferLocationInput {
  readonly label?: string;
  readonly contact?: string;
  readonly address?: string;
  readonly city: string;
  readonly zone: string;
  readonly zipCode: string;
  readonly country: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly dateStart: string;
}

export interface ApiRealOfferShipmentInput {
  readonly id?: string;
  readonly classType?: string;
  readonly deferPaymentInDays?: number;
  readonly goods?: ApiRealOfferShipmentGoodInput[];
  readonly hazardousProduct?: string;
  readonly onuNumber?: string;
  readonly packageNb?: number;
  readonly registration?: string;
  readonly strapNb?: number;
}

export interface ApiRealOfferShipmentGoodInput {
  readonly type: string;
  readonly count: number;
}

export interface ApiRealOfferCarrierInput {
  readonly address?: string;
  readonly zipCode?: string;
  readonly city?: string;
  readonly contact: ApiContactInput;
  readonly customerContact: ApiCustomerContactInput;
}

export interface ApiContactInput {
  readonly id?: string;
  readonly name?: string;
  readonly fax?: string;
  readonly phone: string;
  readonly email?: string;
}

export interface ApiCustomerContactInput {
  readonly id?: string;
  readonly name: string;
  readonly country: string;
}

export interface ApiRealOffer {
  readonly id: string | null;
  readonly offerId: string;
  readonly orderRef: string | null;
  readonly contact: ApiRealOfferContact;
  readonly customerContact: ApiRealOfferCustomerContact;
  readonly goodType: string;
  readonly truckTypes: string[];
  readonly hazardousMaterials: boolean;
  readonly lading: ApiRealOfferLocation;
  readonly delivery: ApiRealOfferLocation;
  readonly distance: number | null;
  readonly shipment: ApiRealOfferShipment;
  readonly carrier: ApiRealOfferCarrier;
  readonly length: number;
  readonly volume: number | null;
  readonly weight: number;
  readonly price: ApiPrice | null;
  readonly notes: string | null;
}

export interface ApiRealOfferLocation {
  readonly label: string | null;
  readonly address: string | null;
  readonly city: string;
  readonly zone: string;
  readonly zipCode: string | null;
  readonly country: string;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly dateStart: string | null;
}

export interface ApiRealOfferCarrier {
  readonly address: string | null;
  readonly zipCode: string | null;
  readonly city: string | null;
  readonly contact: ApiRealOfferContact;
  readonly customerContact: ApiRealOfferCustomerContact;
}

export interface ApiRealOfferContact {
  readonly id: string | null;
  readonly civility: string | null;
  readonly name: string | null;
  readonly email: string | null;
  readonly phone: string | null;
  readonly mobile: string | null;
  readonly fax: string | null;
  readonly country: string | null;
  readonly languages: string[];
}

export interface ApiRealOfferCustomerContact {
  readonly id: string | null;
  readonly name: string | null;
  readonly zone: string | null;
  readonly country: string | null;
  readonly logo: string | null;
}

export interface ApiRealOfferShipment {
  readonly id: string | null;
  readonly classType: string | null;
  readonly deferPaymentInDays: number | null;
  readonly goods: ApiRealOfferShipmentGood[] | null;
  readonly hazardousProduct: string | null;
  readonly isFull: boolean;
  readonly returnablePackage: boolean;
  readonly onuNumber: string | null;
  readonly packageNb: number | null;
  readonly registration: string | null;
  readonly strapNb: number | null;
}

export interface ApiRealOfferShipmentGood {
  readonly type: string;
  readonly count: number;
}

export interface ApiResponsePagination {
  limit: number;
  pageCount: number;
  currentPage: number;
  totalCount: number;
}

export interface ApiCollectionResponse<T> {
  items: T[];
}

export interface ApiPaginatedCollectionResponse<T> extends ApiCollectionResponse<T> {
  pagination: ApiResponsePagination;
}

export interface ApiProfile {
  key: string;
  value: string;
  default: boolean;
}

export interface ApiOfferAction {
  type: number;
  actionExchanges: ApiOfferActionExchange[];
  createdAt: string;
  tracedAt: string;
}

export interface ApiOfferActionExchange {
  label: string;
  abbreviation: string;
  code: string;
  type: number;
  createdAt: string;
}

export interface ApiHistoryFilters {
  fromCountry: string;
  fromZone: string;
  toCountry: string;
  toZone: string;
  goodType: string;
}

export interface ApiConsultant {
  readonly id: string;
  readonly name: string;
  readonly customer: {
    readonly id: string;
    readonly name: string;
    readonly dateInsert: string | null;
  };
  readonly email: string | null;
  readonly phone: string | null;
}

export interface ApiActiveSearch {
  id: string;
  name: string;
  email: string | null;
  phone: string | null;
  customer: {
    id: string;
    name: string;
    dateInsert: string | null;
  };
  lastConsultationDate: string;
}

export class OfferNotFoundError extends Error {}
export class OfferRefreshRestrictionDelayNotElapsedError extends Error {}
