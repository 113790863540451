import { AbortOptions, Collection, PaginationOptions } from '@/shared/dataProviders/common';
import {
  B2PWebCollection,
  B2PWebError,
  handleB2PWebResponse,
  mapToCollection,
} from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { CustomerCommunities, PartnerCommunity } from '@/shared/dataProviders/partner';
import { ApiCustomerCommunities, ApiPartnerCommunity } from '@/shared/dataProviders/partner/api';
import { mapToCustomerCommunities, mapToPartnerCommunity } from '@/shared/dataProviders/partner/mappers';

export class PartnerCommunityDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchAll(options?: AbortOptions & PaginationOptions): Promise<Collection<PartnerCommunity>> {
    const items = handleB2PWebResponse(
      await this.client.get<B2PWebCollection<ApiPartnerCommunity>, B2PWebError>('partner/communities', {
        params: {
          page: options?.page,
          perPage: options?.perPage,
        },
        signal: options?.signal,
      })
    );

    return mapToCollection(items, mapToPartnerCommunity);
  }

  async fetchCustomer(customerId: string, options?: AbortOptions): Promise<CustomerCommunities> {
    const items = handleB2PWebResponse(
      await this.client.get<ApiCustomerCommunities, B2PWebError>(`partner/communities/${customerId}`, {
        signal: options?.signal,
      })
    );

    return mapToCustomerCommunities(items);
  }

  async request(customerId: string, options?: AbortOptions): Promise<void> {
    return handleB2PWebResponse(
      await this.client.post<void, { customerId: string }, B2PWebError>(
        `partner/requests/${customerId}`,
        {
          customerId,
        },
        {
          signal: options?.signal,
        }
      )
    );
  }

  async leave(communityId: string) {
    handleB2PWebResponse(await this.client.delete<void, B2PWebError>(`partner/communities/${communityId}`));
  }
}
