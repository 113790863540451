import { uniqueId } from 'lodash-es';
import { ref } from 'vue';

export type AlertType = 'info' | 'success' | 'error' | 'warning';

const notification = ref<{ id: string; message: string; type: AlertType }>();
const closeTimeoutId = ref<number>();

export function useAlerts() {
  const clear = (): void => {
    closeTimeoutId.value && clearTimeout(closeTimeoutId.value);
    notification.value = undefined;
  };

  const notify = (message: string, type: AlertType, timeout?: number): void => {
    clear();

    notification.value = {
      id: uniqueId(),
      message: message,
      type: type,
    };

    closeTimeoutId.value = setTimeout(clear, timeout ?? 3000);
  };

  return {
    clear,
    notify,
    notification,
  };
}
