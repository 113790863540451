import { Acl } from '@/shared/acl';
import { getParameter, OfferDeleteCauseValue, ParameterType, SharedOfferValue } from '@/shared/dataProviders/admin';
import { Auth } from '@/store/modules/app';

export enum Permission {
  FlowMapAccess = 'ACCESS.flowMap',

  SearchManage = 'MANAGE.search',

  OfferManage = 'MANAGE.offer',
  OfferCreate = 'CREATE.offer',
  OfferDelete = 'DELETE.offer',
  OfferDuplicate = 'DUPLICATE.offer',
  OfferRefresh = 'REFRESH.offer',
  OfferUpdate = 'UPDATE.offer',
  OfferShowHistory = 'SHOW.offer.historic',
  OfferViewShared = 'VIEW.SharedOffer',
  OfferManageShared = 'MANAGE.SharedOffer',
  OfferSearchSupplement = 'SEARCH.offer.supplement',
  OfferSelectDeleteCause = 'SELECT.offer.deleteCause',
  OfferEditCharterConfirmation = 'EDIT.offer.charterConfirmation',

  DirectoryManageMyCustomerFile = 'MANAGE.directory.myCustomerFile',
  DirectorySendInfo = 'SEND.directory',
  DirectorySearch = 'SEARCH.directory',
  DirectoryManageComments = 'MANAGE.directory.comments',
  DirectoryManageForbiddings = 'MANAGE.directory.forbiddings',

  /**
   * As community manager
   * Grants access to communities management ("Partners" module)
   * Allows to display own communities and invite members
   */
  CommunityManage = 'MANAGE.community',

  /**
   * As community member
   * Allows to send request to join communities and leave communities
   */
  PartnerCommunityManage = 'MANAGE.partner.community',

  S3PWebTraceabilityPassAccess = 'ACCESS.s3pweb.traceabilityPass',

  VehicleManage = 'MANAGE.vehicle',
  VehicleSearch = 'SEARCH.vehicle',

  AdminManage = 'MANAGE.admin',
}

export const acl = new Acl<Permission>();

export async function getPermissions(auth: Auth): Promise<Permission[]> {
  return [...getRolesPermissions(auth), ...(await getParametersPermissions())];
}

function getRolesPermissions(auth: Auth) {
  const permissions: Permission[] = [];

  if (auth.roles.includes('admin')) {
    permissions.push(
      Permission.AdminManage,

      // Directory
      Permission.DirectoryManageComments,
      Permission.DirectoryManageForbiddings,

      // Partner communities
      Permission.PartnerCommunityManage
    );
  }

  if (auth.roles.includes('directory_manager')) {
    permissions.push(Permission.DirectoryManageMyCustomerFile, Permission.DirectorySendInfo);
  }

  if (auth.roles.includes('directory_operator')) {
    permissions.push(Permission.DirectorySearch, Permission.DirectorySendInfo);
  }

  if (auth.roles.includes('community_manager')) {
    permissions.push(Permission.CommunityManage);
  }

  if (auth.roles.includes('flow_operator')) {
    permissions.push(Permission.FlowMapAccess);
  }

  if (auth.roles.includes('freight_operator')) {
    permissions.push(Permission.SearchManage);
  }

  if (auth.roles.includes('freight_confirm')) {
    permissions.push(Permission.OfferEditCharterConfirmation);
  }

  if (auth.roles.includes('freight_history')) {
    permissions.push(Permission.OfferShowHistory);
  }

  if (auth.roles.includes('freight_owner')) {
    permissions.push(
      Permission.OfferManage,
      Permission.OfferCreate,
      Permission.OfferDelete,
      Permission.OfferDuplicate,
      Permission.OfferRefresh,
      Permission.OfferUpdate,
      Permission.OfferSearchSupplement
    );
  } else if (auth.roles.includes('freight_reader')) {
    permissions.push(Permission.OfferManage, Permission.OfferDelete, Permission.OfferSearchSupplement);
  }

  if (auth.roles.includes('s3p_access')) {
    permissions.push(Permission.S3PWebTraceabilityPassAccess);
  }

  if (auth.roles.includes('vehicle_owner')) {
    permissions.push(Permission.VehicleManage);
  }

  if (auth.roles.includes('vehicle_operator')) {
    permissions.push(Permission.VehicleSearch);
  }

  return permissions;
}

async function getParametersPermissions() {
  const permissions: Permission[] = [];

  const [sharedOfferParameter, offerSelectDeleteCauseParameter] = await Promise.all([
    getParameterValue(ParameterType.SHARED_OFFER),
    getParameterValue(ParameterType.OFFER_DELETE_CAUSE),
  ]);

  if (sharedOfferParameter === SharedOfferValue.VIEW) {
    permissions.push(Permission.OfferViewShared);
  } else if (sharedOfferParameter === SharedOfferValue.MANAGE) {
    permissions.push(Permission.OfferManageShared);
  }

  if (offerSelectDeleteCauseParameter === OfferDeleteCauseValue.ENABLED) {
    permissions.push(Permission.OfferSelectDeleteCause);
  }

  return permissions;
}

async function getParameterValue<T extends ParameterType>(id: T) {
  try {
    return (await getParameter(id))?.value;
  } catch (e) {
    return;
  }
}
