import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useChangelogStore = defineStore('changelog', () => {
  const isModalOpen = ref<boolean>(false);

  return {
    isModalOpen,
  };
});
