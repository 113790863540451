import { client, isAxiosError } from '@/shared/api/directory/client';
import { composeSearchRequestParams } from '@/shared/api/directory/mappers';
import {
  AbortOptions,
  Axis,
  AxisInput,
  Collection,
  Comment,
  CommentInput,
  CustomerFile,
  CustomerScore,
  CustomerScoreListResponse,
  Document,
  EnumItem,
  MyCustomerFile,
  MyCustomerFileInput,
  MyDocument,
  PaginationOptions,
  SearchOptions,
  SearchResult,
  VehicleType,
  VehicleTypeInput,
} from '@/shared/api/directory/types';
import { FormValidationError } from '@/shared/api/offer/write';
import { cancellable } from '@/shared/api/util/cancellable';

export class ForbiddenError extends Error {}
export class NotFoundError extends Error {}

export async function search(options?: SearchOptions): Promise<Collection<SearchResult>> {
  try {
    const { data } = await cancellable(
      client.get<Collection<SearchResult>>('carriers', {
        params: options ? composeSearchRequestParams(options) : undefined,
        signal: options?.signal,
      })
    );

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 403) {
      throw new ForbiddenError(e.response.data.message);
    }

    throw e;
  }
}

export async function getScores(ids: string[], options?: AbortOptions): Promise<Record<string, CustomerScore>> {
  const { data } = await cancellable(
    client.get<CustomerScoreListResponse>('scores', {
      params: { ids },
      signal: options?.signal,
    })
  );
  return data.items;
}

export async function getCustomerFile(id: string, options?: AbortOptions): Promise<CustomerFile> {
  try {
    const { data } = await cancellable(
      client.get<CustomerFile>(`carriers/${id}`, {
        // @todo move relations load on another action
        params: {
          relations: ['axes', 'vehicleTypes'],
        },
        signal: options?.signal,
      })
    );

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      throw new NotFoundError(e.response.data.message);
    }

    throw e;
  }
}

export async function getMyCustomerFile(options?: AbortOptions): Promise<MyCustomerFile> {
  const { data } = await cancellable(
    client.get<MyCustomerFile>(`carriers/my`, {
      params: {
        relations: ['axes', 'vehicleTypes'],
      },
      signal: options?.signal,
    })
  );

  return data;
}

export async function createOrUpdateMyCustomerFile(input: MyCustomerFileInput): Promise<MyCustomerFile> {
  try {
    const { data } = await cancellable(
      client.post<MyCustomerFile>(`carriers/my?relations[]=axes&relations[]=vehicleTypes`, input)
    );

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while create or update carrier', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function removeMyVehicleType(id: string): Promise<void> {
  await client.delete<void>(`carriers/my/vehicleTypes/${id}`);
}

export async function updateMyVehicleType(input: VehicleTypeInput & { id: VehicleType['id'] }): Promise<VehicleType> {
  try {
    const { id, ...VehicleTypeInput } = input;
    const { data } = await client.put<VehicleType>(`carriers/my/vehicleTypes/${id}`, VehicleTypeInput);

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while create vehicle', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function createMyVehicleType(input: VehicleTypeInput): Promise<VehicleType> {
  try {
    const { data } = await client.post<VehicleType>(`carriers/my/vehicleTypes`, input);

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while create vehicle', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function removeMyAxis(id: string): Promise<void> {
  await client.delete<void>(`carriers/my/axes/${id}`);
}

export async function createMyAxis(input: AxisInput): Promise<Axis> {
  try {
    const { data } = await client.post<Axis>(`carriers/my/axes`, input);

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while create axes', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function getCustomerFileComments(
  customerId: string,
  options?: AbortOptions & PaginationOptions
): Promise<Collection<Comment>> {
  const { data } = await cancellable(
    client.get<Collection<Comment>>(`carriers/${customerId}/comments`, {
      params: {
        page: options?.page,
        per_page: options?.perPage,
      },
      signal: options?.signal,
    })
  );

  return data;
}

export async function createComment(customerId: string, input: CommentInput): Promise<Comment> {
  try {
    const { data } = await client.post<Comment>(`carriers/${customerId}/comments`, input);

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while create directory comment', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function deleteComment(customerId: string, commentId: string): Promise<void> {
  await client.delete<void>(`carriers/${customerId}/comments/${commentId}`);
}

export async function getCustomerFileDocuments(id: string, options?: AbortOptions): Promise<Collection<Document>> {
  try {
    const { data } = await cancellable(
      client.get<Collection<Document>>(`carriers/${id}/documents`, {
        signal: options?.signal,
      })
    );

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      throw new NotFoundError(e.response.data.message);
    }

    throw e;
  }
}

export async function getVehicleTypes(options?: AbortOptions): Promise<EnumItem<number>[]> {
  const { data } = await cancellable(
    client.get<EnumItem<number>[]>('enum/vehicleTypes', {
      signal: options?.signal,
    })
  );

  return data;
}

export async function getMyDocuments(options?: AbortOptions): Promise<Collection<MyDocument>> {
  const { data } = await cancellable(
    client.get<Collection<MyDocument>>(`carriers/my/documents`, {
      signal: options?.signal,
    })
  );

  return data;
}

export async function sendMyDocuments(
  recipientEmail: string,
  documentTypeIds: Document['documentType']['id'][],
  language: string
): Promise<void> {
  try {
    await client.post(`carriers/my/documents/send`, { email: recipientEmail, documentTypeIds, language });
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError('Error occurred while send my documents', e.response.data.errors ?? []);
    }

    throw e;
  }
}

export async function allowLoading(id: string, comments?: string): Promise<void> {
  await client.put(`carriers/${id}/loading/allow`, {
    comments,
  });
}

export async function warnLoading(id: string, comments?: string): Promise<void> {
  await client.put(`carriers/${id}/loading/warned`, {
    comments,
  });
}

export async function forbidLoading(id: string, comments?: string): Promise<void> {
  await client.put(`carriers/${id}/loading/forbid`, {
    comments,
  });
}

export async function allowSubcontracting(id: string, comments?: string): Promise<void> {
  await client.put(`carriers/${id}/subcontracting/allow`, {
    comments,
  });
}

export async function forbidSubcontracting(id: string, comments?: string): Promise<void> {
  await client.put(`carriers/${id}/subcontracting/forbid`, {
    comments,
  });
}
