/* eslint-disable @typescript-eslint/no-explicit-any */

import { ConnectionEvent, EventSourcePolyfill } from 'event-source-polyfill';

import { CancelledRequestError } from '@/shared/abort-manager';

function isConnectionEvent(event: Event): event is ConnectionEvent {
  return 'status' in event && 'statusText' in event && 'headers' in event;
}

interface EventStreamMessage {
  readonly type: string;
  readonly payload: any;
}

function isEventStreamMessage(message: any): message is EventStreamMessage {
  return 'type' in message && 'payload' in message;
}

export interface RequestAuthorization {
  credentials?: RequestCredentials;
  headers?: Record<string, string>;
}

export interface AuthorizationAdapter {
  getAuthorization(signal: AbortSignal): Promise<RequestAuthorization>;
}

// TODO Make those constants configurable
const DEFAULT_HEARTBEAT_TIMEOUT = 300000; // 5 minutes
const DEFAULT_MAX_CONNECTION_ATTEMPTS = 5;
const DEFAULT_INTERVAL_BETWEEN_CONNECTION_ATTEMPTS = 30000; // 30 seconds

export default class EventStream<M = Record<any, any>> {
  private abortController?: AbortController;
  private connectionAttempts = 0;
  private connectionTimeoutHandle?: number;
  private source?: EventSourcePolyfill;
  private handlersByType: Map<any, Set<(payload: any) => void>> = new Map();

  constructor(
    private subscriptionUrl: string,
    private authorizationAdapter?: AuthorizationAdapter
  ) {}

  async open(): Promise<void> {
    try {
      this.close();

      if (this.connectionAttempts > DEFAULT_MAX_CONNECTION_ATTEMPTS) {
        throw new Error('Max connection attempts reached');
      }

      this.abortController = new AbortController();

      const authorization = await this.authorizationAdapter?.getAuthorization(this.abortController.signal);

      this.source = new EventSourcePolyfill(this.subscriptionUrl, {
        headers: authorization?.headers,
        heartbeatTimeout: DEFAULT_HEARTBEAT_TIMEOUT,
        withCredentials: (authorization?.credentials ?? 'omit') !== 'omit',
      });

      this.source.addEventListener('open', () => {
        this.connectionAttempts = 0;
      });

      this.source.addEventListener('message', (event: MessageEvent) => {
        const message = JSON.parse(event.data);

        if (!isEventStreamMessage(message)) {
          return;
        }

        this.handlersByType.get(message.type)?.forEach((handler) => {
          handler(message.payload);
        });
      });

      this.source.addEventListener('error', (event: Event) => {
        if (isConnectionEvent(event) && event.status === 401) {
          this.connectionAttempts += 1;

          this.close();

          this.connectionTimeoutHandle = setTimeout(() => {
            this.open();
          }, DEFAULT_INTERVAL_BETWEEN_CONNECTION_ATTEMPTS);

          return;
        }

        if (this.source?.readyState === EventSourcePolyfill.CLOSED) {
          this.close();
        }
      });
    } catch (error) {
      if (error instanceof CancelledRequestError) {
        return;
      }

      throw error;
    }
  }

  close(): void {
    this.abortController?.abort();
    this.abortController = undefined;

    clearTimeout(this.connectionTimeoutHandle);
    this.connectionTimeoutHandle = undefined;

    this.source?.close();
    this.source = undefined;
  }

  addEventListener<T extends keyof M>(type: T, handler: (payload: M[T]) => void): void {
    let handlers = this.handlersByType.get(type);

    if (!handlers) {
      this.handlersByType.set(type, (handlers = new Set()));
    }

    handlers.add(handler);
  }

  removeEventListener<T extends keyof M>(type: T, handler: (payload: M[T]) => void): void {
    const handlers = this.handlersByType.get(type);

    if (!handlers) {
      return;
    }

    handlers.delete(handler);

    if (!handlers.size) {
      this.handlersByType.delete(type);
    }
  }
}
