import { DateTime } from 'luxon';

import config from '@/shared/config';

export interface ApiCustomerContact {
  id: string;
  name: string;
  country: string;
  zone: string;
  logo: string | null;
  dateInsert: string | null;
}

export interface CustomerContact {
  id: string;
  country: string;
  name: string;
  logo: string | null;
  zone: string;
  dateInsert: DateTime | null;
}

function composeLogoUrl(path: string): string {
  if (!path) {
    return '';
  }

  const baseUrl = config.urls.customerLogo.endsWith('/')
    ? config.urls.customerLogo.slice(0, -1)
    : config.urls.customerLogo;

  path = path.startsWith('/') ? path.slice(1, path.length) : path;

  return `${baseUrl}/${path}`;
}

export function fromApi(customerContact: ApiCustomerContact): CustomerContact {
  return {
    ...customerContact,

    logo: (customerContact.logo && composeLogoUrl(customerContact.logo)) || null,
    dateInsert: customerContact.dateInsert ? DateTime.fromISO(customerContact.dateInsert) : null,
  };
}
