import * as readApi from '@/shared/api/offer/read';
import * as writeApi from '@/shared/api/offer/write';
import { PaginationOptions, SortingOptions } from '@/shared/dataProviders/common';

import {
  composeActiveSearches,
  composeApiRealOfferInput,
  composeCollection,
  composeConsultants,
  composeRealOffer,
  composeRealOffers,
} from './mappers';
import { ActiveSearch, Collection, Consultant, HistoryFilters, RealOffer, RealOfferInput } from './types';

export async function createOrUpdateRealOffer(offerId: string, input: RealOfferInput): Promise<RealOffer> {
  return composeRealOffer(await writeApi.createOrUpdateRealOffer(offerId, composeApiRealOfferInput(input)));
}

export async function fetchRealOffer(offerId: string): Promise<RealOffer> {
  return composeRealOffer(await readApi.fetchRealOffer(offerId));
}

export function generateCharterConfirmAsPdf(id: string): Promise<Blob> {
  return readApi.generateCharterConfirmAsPdf(id);
}

export function sendRealOfferByEmail(id: string): Promise<void> {
  return writeApi.sendRealOfferByEmail(id);
}

export async function fetchActiveSearchHistory(
  params: HistoryFilters & PaginationOptions & SortingOptions,
  signal?: AbortSignal
): Promise<Collection<ActiveSearch>> {
  return composeCollection(await readApi.fetchActiveSearchHistory(params, { signal }), composeActiveSearches);
}

export async function fetchWorkHistoryWithCarriers(
  params: HistoryFilters & PaginationOptions,
  signal?: AbortSignal
): Promise<Collection<RealOffer>> {
  return composeCollection(await readApi.fetchWorkHistoryWithCarriers(params, { signal }), composeRealOffers);
}

export async function fetchTopConsultantsHistory(
  params: HistoryFilters & PaginationOptions,
  signal?: AbortSignal
): Promise<Collection<Consultant>> {
  return composeCollection(await readApi.fetchTopConsultantsHistory(params, { signal }), composeConsultants);
}

export async function generateS3PWebToken(signal?: AbortSignal): Promise<string> {
  return readApi.generateS3PWebToken(signal);
}
