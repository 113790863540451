import { offset } from '@floating-ui/dom';
import { uniq } from 'lodash-es';
import Shepherd from 'shepherd.js';
import { App, Directive } from 'vue';

import { get as readFromLocalStorage, set as writeToLocalStorage } from '@/store/localStorage';

interface IntroducePluginOptions {
  translate: (key: string) => string;
}

interface IntroduceOptions {
  id: string;
  text: string;
}

const STORAGE_KEY = 'introduce.displayedSteps';

function getDisplayedSteps() {
  return readFromLocalStorage<string[]>(STORAGE_KEY, []);
}

function saveDisplayedSteps(ids: string[]) {
  writeToLocalStorage(STORAGE_KEY, uniq(ids));
}

export const IntroducePlugin = {
  install(app: App, options: IntroducePluginOptions = { translate: (key: string) => key }) {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        floatingUIOptions: {
          middleware: [offset(({ rects }) => rects.reference.height / 2 + 5)],
        },
        buttons: [
          {
            text() {
              return options.translate('Compris');
            },
            action() {
              this.next();
            },
          },
        ],
      },
    });

    app.directive('introduce', <Directive<HTMLElement, IntroduceOptions>>{
      mounted(el, binding) {
        const displayedSteps = getDisplayedSteps();

        if (displayedSteps.includes(binding.value.id)) {
          return;
        }

        tour.addStep({
          attachTo: {
            element: el,
            on: 'bottom',
          },
          id: binding.value.id,
          text: binding.value.text,
        });

        el.addEventListener('click', () => tour.complete(), {
          capture: true,
          once: true,
        });

        if (!tour.isActive()) {
          tour.start();
        }
      },

      unmounted(el, binding) {
        tour.removeStep(binding.value.id);
      },
    });

    tour.on('show', (event: { step: Shepherd.Step }) => {
      saveDisplayedSteps([...getDisplayedSteps(), event.step.id]);
    });
  },
};
