import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import { getUserSettings } from '@/shared/dataProviders/admin';
import { VehicleProfile } from '@/shared/enums/vehicleProfile';
import { RoutingMapOptions } from '@/shared/types';
import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';
import { useAppStore } from '@/store/modules/app';

const DEFAULT_MAX_SEARCH_NUMBER_ALLOWED = 10;
const DEFAULT_FEEDBACK_REQUESTED = false;

export const useSettingsStore = defineStore('settings', () => {
  const maxSearchNumberAllowed = ref(DEFAULT_MAX_SEARCH_NUMBER_ALLOWED);
  const feedbackRequested = ref(DEFAULT_FEEDBACK_REQUESTED);
  const offerListUserIdFilter = ref(localStorageGet<string>(keys.offerListUserIdFilter, useAppStore().auth?.id ?? ''));
  const routingMapOptions = ref<RoutingMapOptions>(
    localStorageGet<RoutingMapOptions>(keys.routingMapOptions, {
      avoidTolls: false,
      optimization: 'time',
      vehicleProfile: VehicleProfile.AXLE_2_AND_WEIGHT_12T_OR_MORE,
    })
  );

  function setOfferListUserIdFilter(userId: string): void {
    offerListUserIdFilter.value = userId;
  }

  function setRoutingMapOptions(options: RoutingMapOptions): void {
    routingMapOptions.value = options;
  }

  watch(offerListUserIdFilter, () => {
    localStorageSet(keys.offerListUserIdFilter, offerListUserIdFilter.value);
  });

  watch(routingMapOptions, () => {
    localStorageSet(keys.routingMapOptions, routingMapOptions.value);
  });

  getUserSettings()
    .then((settings: { maxSearchNumberAllowed: number; feedbackRequested: boolean }) => {
      maxSearchNumberAllowed.value = settings.maxSearchNumberAllowed;
      feedbackRequested.value = settings.feedbackRequested;
    })
    .catch(() => {
      maxSearchNumberAllowed.value = DEFAULT_MAX_SEARCH_NUMBER_ALLOWED;
      feedbackRequested.value = DEFAULT_FEEDBACK_REQUESTED;
    });

  return {
    feedbackRequested,
    maxSearchNumberAllowed,
    offerListUserIdFilter,
    routingMapOptions,

    setOfferListUserIdFilter,
    setRoutingMapOptions,
  };
});
