import { AbortOptions } from '@/shared/dataProviders/common';
import { getVehicleTypes } from '@/shared/dataProviders/directory';
import { RefreshOptions } from '@/shared/enums/types';
import translator from '@/shared/locale';

let isLoaded = false;
let values: Record<number, string> = {};

export async function load(options?: AbortOptions & RefreshOptions): Promise<void> {
  if (isLoaded && !options?.refresh) {
    return;
  }

  values = Object.fromEntries((await getVehicleTypes(options)).map((item) => [item.key, translator().t(item.value)]));
  isLoaded = true;
}

export function list(): Record<number, string> {
  return values;
}

export function label(key: number): string | undefined {
  return values[key];
}
