import { AbortOptions } from '@/shared/dataProviders/common';
import { B2PWebError, handleB2PWebResponse } from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { Enum } from '@/shared/dataProviders/enum';
import { B2PWebEnumResponse } from '@/shared/dataProviders/enum/b2pweb';

export class PostEnumDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchPartnerExchanges(options?: AbortOptions): Promise<Enum<string, string>[]> {
    return Object.values(
      handleB2PWebResponse(
        await this.client.get<B2PWebEnumResponse<string, string>, B2PWebError>('/enum/partnerExchanges', {
          signal: options?.signal,
        })
      )
    );
  }
}
