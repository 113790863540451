import { AxiosError, AxiosInstance } from 'axios';

import { useAppStore } from '@/store/modules/app';

export function setupAuthentication(instance: AxiosInstance): void {
  instance.interceptors.request.use((config) => {
    const token = useAppStore().auth?.accessToken;

    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }

    return config;
  });

  instance.interceptors.response.use(undefined, async (error: AxiosError) => {
    if (error.response?.status === 401) {
      await useAppStore().logout();
      return;
    }

    throw error;
  });
}
