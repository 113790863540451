import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LIcon = _resolveComponent("LIcon")!

  return (_openBlock(), _createBlock(_component_LIcon, {
    "class-name": "",
    "icon-anchor": _ctx.iconAnchor,
    "icon-size": _ctx.iconSize
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["h-full border-2 rounded-t-full rounded-bl-full rotate-45", _ctx.containerClass])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["h-full flex justify-center items-center font-bold -rotate-45", _ctx.contentClass])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ], 2)
    ]),
    _: 3
  }, 8, ["icon-anchor", "icon-size"]))
}