import { DateTime } from 'luxon';

export interface Customer {
  readonly id: string;
  readonly name: string;
  readonly zipCode: string;
  readonly city: string | null;
  readonly country: string;
  readonly logo: string | null;
  readonly dateInsert: DateTime | null;
}

export interface Community {
  readonly id: string;
  readonly label: string;
  readonly enabledCount: number;
  readonly disabledCount: number;
}

export interface CommunityInvitationPostValues {
  customers: string[];
  exchanges: string[];
}

export interface CommunityPartnerFilters {
  exchanges?: string[];
  enabled?: boolean;
  customerName?: string;
  customerCountry?: string;
  customerZones?: string;
}

export interface CommunityPartner {
  _id: string;
  id: string;
  label: string;
  enabled: boolean;
  createdAt: DateTime;
  updatedAt: DateTime | null;
  partner: Customer;
}

export interface CustomerCommunity {
  id: string;
  label: string;
  enabled: boolean;
}

export interface CustomerCommunities {
  isCommunityManager: boolean;
  hasPendingRequest: boolean;
  items: CustomerCommunity[];
}

export interface PartnerCommunity {
  readonly id: string;
  readonly label: string;
  readonly enabled: boolean;
  readonly createdAt: DateTime;
  readonly owner: Customer;
}

export interface CommunityRequest {
  _id: string;
  sender: {
    id: string;
    name: string;
    country: string;
    zipCode: string;
    city: string;
    logo: string | null;
    score: Score | null;
    dateInsert: DateTime | null;
  };
}

export enum Score {
  NoScore = '500',
  Warning = '0',
  Vigilance = '50',
  Premium = '100',
  Masked = 'masked',
}

export interface MyPartnerCommunity {
  id: string;
  label: string;
  enabled: boolean;
}
