import { AbortOptions } from '@/shared/dataProviders/common';
import { B2PWebError, handleB2PWebResponse } from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { ApiCommunity, ApiMyPartnerCommunity } from '@/shared/dataProviders/partner/api';
import { mapToCommunities, mapToMyPartnerCommunityCollection } from '@/shared/dataProviders/partner/mappers';
import { CommunityInvitationPostValues, MyPartnerCommunity } from '@/shared/dataProviders/partner/types';

export class CommunityDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchAll(options?: AbortOptions) {
    const items = handleB2PWebResponse(
      await this.client.get<ApiCommunity[], B2PWebError>('/my/communities', {
        signal: options?.signal,
      })
    );

    return mapToCommunities(items);
  }

  async invite(data: CommunityInvitationPostValues) {
    handleB2PWebResponse(
      await this.client.post<void, CommunityInvitationPostValues, B2PWebError>('/my/communities/partners', data)
    );
  }

  async fetchPartnerCommunities(partnerId: string, options?: AbortOptions): Promise<MyPartnerCommunity[]> {
    const items = handleB2PWebResponse(
      await this.client.get<ApiMyPartnerCommunity[], B2PWebError>(`/my/communities/partner/${partnerId}/communities`, {
        signal: options?.signal,
      })
    );

    return mapToMyPartnerCommunityCollection(items);
  }
}
