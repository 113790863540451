import { DateTime } from 'luxon';

import { syncedNow } from '@/shared/clock';

export function formatDateAsDelta(date: DateTime, baseDate: DateTime = syncedNow()): string {
  const diff = calculateDelta(date, baseDate);
  return diff > 0 ? `+${diff}` : diff < 0 ? `${diff}` : '';
}

function calculateDelta(date: DateTime, baseDate: DateTime): number {
  return date.startOf('day').diff(baseDate.setZone(date.zone).startOf('day'), 'days').days;
}
