import { Coordinate } from '@/shared/api/cartography';

import client from './axios';

export interface RouteOptions {
  readonly avoidTolls?: boolean;
  readonly language?: string;
  readonly optimization?: OptimizationType;
  readonly vehicleProfile?: VehicleProfileType;
  readonly vehicleAxleCount?: number;
  readonly vehicleHeight?: number;
  readonly vehicleLength?: number;
  readonly vehicleWeight?: number;
  readonly vehicleWidth?: number;
}

export type OptimizationType = 'distance' | 'time';

export enum VehicleProfileType {
  Delivery = 'delivery',
  Truck = 'truck',
  TruckL_2 = 'truckl_2',
  TruckL_3 = 'truckl_3',
  TruckL_4 = 'truckl_4',
}

export interface CalculateRoutesResponse {
  readonly items: Route[];
}

export interface Route {
  readonly distance: number;
  readonly tollCost: number;
  readonly travelTime: number;
  readonly polyline: Coordinate[];
  readonly legs: RouteLeg[];
}

export interface RouteLeg {
  readonly instructions: Instruction[];
}

export interface Instruction {
  readonly maneuverType: string;
  readonly text: string;
  readonly distanceFromStart: number;
  readonly travelTimeFromStart: number;
}

export async function calculateRoutes(waypoints: Coordinate[], options?: RouteOptions): Promise<Route[]> {
  const { data } = await client.get<CalculateRoutesResponse>('routes', {
    params: {
      ...options,
      waypoints: waypoints.map((waypoint) => waypoint.join(',')),
    },
  });

  // TODO Handle API errors

  return data.items;
}
