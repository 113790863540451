import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useEnum } from '@/shared/enums/useEnum';

export const useDirectoryLoadingRestrictionEnum = () => {
  const { t } = useI18n();

  return useEnum(
    computed(() => [
      { key: true, value: t('Autorisées') },
      { key: false, value: t('Interdites et déconseillées') },
    ]),
    { sort: { by: 'text' } }
  );
};
