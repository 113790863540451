import { Coordinate } from '@/shared/api/cartography';
import { AbortOptions } from '@/shared/api/cartography/types';
import { cancellable } from '@/shared/api/util/cancellable';

import client from './axios';

export interface RouteOptions {
  readonly language?: string;
}

interface BaseLocation<T> {
  readonly address: T;
  readonly position: Coordinate;
}

export type Location = BaseLocation<Address>;
export type ExactLocation = BaseLocation<ExactAddress>;

export interface Address {
  readonly city: string;
  readonly zipCode: string;
  readonly country: string;
}

export interface ExactAddress extends Address {
  readonly houseNumber?: string;
  readonly street: string;
}

export interface SearchLocationsResponse {
  readonly items: Location[];
}

export interface SearchExactLocationsResponse {
  readonly items: ExactLocation[];
}

export async function searchByPosition(position: Coordinate, options?: RouteOptions): Promise<Location[]> {
  const { data } = await client.get<SearchLocationsResponse>('locations/position', {
    params: {
      ...options,
      position: position.join(','),
    },
  });
  return data.items;
}

export async function searchByText(text: string, options?: AbortOptions & RouteOptions): Promise<ExactLocation[]> {
  const { data } = await cancellable(
    client.get<SearchExactLocationsResponse>('locations/text', {
      params: {
        language: options?.language,
        text: text,
      },
      signal: options?.signal,
    })
  );
  return data.items;
}
