import Keycloak from 'keycloak-js';

import config from '@/shared/config';

const keycloak = new Keycloak({
  clientId: config.auth.clientId,
  realm: config.auth.realm,
  url: config.auth.url,
});

export default keycloak;
