import { App } from 'vue';

import {
  HotkeysPlugin,
  IntroducePlugin,
  RootChildPlugin,
  TestIdPlugin,
  TooltipPlugin,
  TrackIdPlugin,
} from '@/shared/directives';
import translator from '@/shared/locale';

export default (app: App) => {
  app.use(IntroducePlugin, {
    translate: (key: string) => translator().t(key),
  });
  app.use(HotkeysPlugin);
  app.use(RootChildPlugin);
  app.use(TestIdPlugin);
  app.use(TooltipPlugin, {
    allowHTML: true,
    delay: [100, 0],
    theme: 'light',
  });
  app.use(TrackIdPlugin);
};
