import { computed } from 'vue';

import { useMediaQuery } from '@/shared/composables/useMediaQuery';
import { FontSize, useUISettings } from '@/store/modules/ui-settings';

type Breakpoints<K extends string = string> = Record<K, number>;

export function useBreakpoints<K extends string>(breakpoints: Breakpoints<K>) {
  const settings = useUISettings();

  const size = computed(() => {
    switch (settings.fontSize) {
      case FontSize.Small:
        return -120;
      default:
      case FontSize.Medium:
        return 0;
      case FontSize.Large:
        return 120;
    }
  });

  return {
    get(breakpoint: K) {
      return breakpoints[breakpoint] + size.value;
    },

    maxWidth(breakpoint: K) {
      return this.get(breakpoint) - 1;
    },

    minWidth(breakpoint: K) {
      return this.get(breakpoint);
    },

    isLessThanOrEqual(breakpoint: K) {
      return useMediaQuery(`(max-width: ${this.maxWidth(breakpoint)}px)`);
    },

    isMoreThanOrEqual(breakpoint: K) {
      return useMediaQuery(`(min-width: ${this.minWidth(breakpoint)}px)`);
    },
  };
}
