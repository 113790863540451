import { DateTime } from 'luxon';

import { Price } from '@/shared/api/offer/types';
import { GoodType } from '@/shared/enums/goodType';
import { TruckType } from '@/shared/enums/truckType';

export type { Collection } from '@/shared/dataProviders/common';

export interface ActiveSearch {
  id: string;
  name: string;
  email: string | null;
  phone: string | null;
  customer: {
    id: string;
    name: string;
    dateInsert: DateTime | null;
  };
  lastConsultationDate: DateTime;
}

export interface RealOfferInput {
  orderRef?: string;
  goodType: string;
  truckTypes?: string[];
  hazardousMaterials?: boolean;
  lading: RealOfferLocationInput;
  delivery: RealOfferLocationInput;
  shipment: RealOfferShipmentInput;
  carrier: RealOfferCarrierInput;
  length: number;
  weight: number;
  volume?: number;
  price?: number;
  currency: string;
  notes?: string;
}

export interface RealOfferLocationInput {
  label: string;
  address: string;
  city: string;
  zone: string;
  zipCode: string;
  country: string;
  latitude: number;
  longitude: number;
  date: DateTime;
}

export interface RealOfferShipmentInput {
  id?: string;
  classType?: string;
  deferPaymentInDays?: number;
  goods?: RealOfferShipmentGoodInput[];
  hazardousProduct?: string;
  isFull?: boolean;
  hasReturnablePackage?: boolean;
  onuNumber?: string;
  packageNb?: number;
  registration?: string;
  strapNb?: number;
}

export interface RealOfferShipmentGoodInput {
  type: Good;
  count: number;
}

export interface RealOfferCarrierInput {
  address?: string;
  zipCode?: string;
  city?: string;
  contact: ContactInput;
  customerContact: CustomerContactInput;
}

export interface ContactInput {
  id?: string;
  name?: string;
  fax?: string;
  phone: string;
  email?: string;
}

export interface CustomerContactInput {
  id?: string;
  name: string;
  country: string;
}

export interface HistoryFilters {
  fromCountry: string;
  fromZone: string;
  toCountry: string;
  toZone: string;
  goodType: GoodType;
}

export interface RealOffer {
  id: string | null;
  offerId: string;
  orderRef: string | null;
  contact: RealOfferContact;
  customerContact: RealOfferCustomerContact;
  goodType: GoodType;
  truckTypes: TruckType[];
  hazardousMaterials: boolean;
  lading: RealOfferLocation;
  delivery: RealOfferLocation;
  distance: number | null;
  shipment: RealOfferShipment;
  carrier: RealOfferCarrier;
  weight: number;
  length: number;
  volume: number | null;
  price: Price | null;
  notes: string | null;
}

export interface RealOfferShipment {
  id: string | null;
  classType: string | null;
  deferPaymentInDays: number | null;
  goods: RealOfferShipmentGood[];
  hazardousProduct: string | null;
  isFull: boolean;
  hasReturnablePackage: boolean;
  packageNb: number | null;
  onuNumber: string | null;
  registration: string | null;
  strapNb: number | null;
}

export interface RealOfferShipmentGood {
  type: Good;
  count: number;
}

export interface RealOfferLocation {
  label: string | null;
  address: string | null;
  city: string;
  zipCode: string | null;
  zone: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
  date: DateTime | null;
}

export interface RealOfferCarrier {
  address: string | null;
  zipCode: string | null;
  city: string | null;
  contact: RealOfferContact;
  customerContact: RealOfferCustomerContact;
}

export interface RealOfferContact {
  id: string | null;
  civility: string | null;
  name: string | null;
  phone: string | null;
  mobile: string | null;
  fax: string | null;
  email: string | null;
  languages: string[];
  country: string | null;
}

export interface RealOfferCustomerContact {
  id: string | null;
  name: string | null;
  country: string | null;
  zone: string | null;
  logo: string | null;
}

export enum Good {
  Bac = 'bac',
  BigBag = 'bigbag',
  Bulk = 'bulk',
  Other = 'other',
  Pallet80 = 'pallet_80',
  Pallet100 = 'pallet_100',
  Pallet120 = 'pallet_120',
  Roll = 'roll',
}

export interface Consultant {
  id: string;
  name: string;
  customer: {
    id: string;
    name: string;
    dateInsert: DateTime | null;
  };
  email: string | null;
  phone: string | null;
}
