import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import { CustomListColor } from '@/shared/enums/colors';
import { Layout } from '@/shared/types';
import { get as readFromLocalStorage, set as writeToLocalStorage } from '@/store/localStorage';

export const STORAGE_KEY_LAYOUT = 'offer.list.layout';
export const STORAGE_KEY_DATA = 'offer.list.data';
export const STORAGE_KEY_TAGS = 'offer.list.tags';
export const STORAGE_KEY_COLORS = 'offer.list.colors';

const AVAILABLE_DATA = ['weight', 'length', 'volume', 'distance'];
const AVAILABLE_TAGS = ['appointment', 'details'];

export const useOfferListSettings = defineStore('offer-list-settings', () => {
  const layout = ref<Layout>(readFromLocalStorage(STORAGE_KEY_LAYOUT, 'row'));
  const data = ref<string[]>(readFromLocalStorage(STORAGE_KEY_DATA, []));
  const tags = ref<string[]>(readFromLocalStorage(STORAGE_KEY_TAGS, []));
  const colors = ref<Record<string, CustomListColor>>(
    readFromLocalStorage(STORAGE_KEY_COLORS, {
      relativeDate: 'list-dark-blue',
      ladingDate: 'list-gray',
      deliveryDate: 'list-gray',
      goodType: 'list-gray',
      truckTypes: 'list-gray',
      weight: 'list-gray',
      length: 'list-gray',
      volume: 'list-gray',
      distance: 'list-gray',
    })
  );

  watch(layout, () => {
    writeToLocalStorage(STORAGE_KEY_LAYOUT, layout.value);
  });

  watch(
    data,
    () => {
      writeToLocalStorage(STORAGE_KEY_DATA, data.value);
    },
    { deep: true }
  );

  watch(
    tags,
    () => {
      writeToLocalStorage(STORAGE_KEY_TAGS, tags.value);
    },
    { deep: true }
  );

  watch(
    colors,
    () => {
      writeToLocalStorage(STORAGE_KEY_COLORS, colors.value);
    },
    { deep: true }
  );

  return {
    layout,
    data: computed({
      get: () => {
        return AVAILABLE_DATA.filter((value) => !data.value.includes(value));
      },
      set: (values) => {
        data.value = AVAILABLE_DATA.filter((value) => !values.includes(value));
      },
    }),
    tags: computed({
      get: () => {
        return AVAILABLE_TAGS.filter((value) => !tags.value.includes(value));
      },
      set: (values) => {
        tags.value = AVAILABLE_TAGS.filter((value) => !values.includes(value));
      },
    }),
    colors,

    setDefaultColors: (category: 'appointment' | 'details') => {
      if (category === 'appointment') {
        colors.value.relativeDate = 'list-dark-blue';
        colors.value.ladingDate = 'list-gray';
        colors.value.deliveryDate = 'list-gray';
      } else if (category === 'details') {
        colors.value.goodType = 'list-gray';
        colors.value.truckTypes = 'list-gray';
        colors.value.weight = 'list-gray';
        colors.value.length = 'list-gray';
        colors.value.volume = 'list-gray';
        colors.value.distance = 'list-gray';
      }
    },

    setPredefinedColors: (category: 'details') => {
      if (category === 'details') {
        colors.value.goodType = 'list-blue';
        colors.value.truckTypes = 'list-green';
        colors.value.weight = 'list-brown';
        colors.value.length = 'list-purple';
        colors.value.volume = 'list-dark-red';
        colors.value.distance = 'list-dark-blue';
      }
    },
  };
});
