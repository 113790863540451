import { Composer, createI18n, I18n, TranslateResult } from 'vue-i18n';

import { datetimeFormats as en_datetimeFormats, translations as en_translations } from '@/shared/locale/en';
import { datetimeFormats as es_datetimeFormats, translations as es_translations } from '@/shared/locale/es';
import { datetimeFormats as fr_datetimeFormats, translations as fr_translations } from '@/shared/locale/fr';
import { datetimeFormats as it_datetimeFormats, translations as it_translations } from '@/shared/locale/it';
import { datetimeFormats as pt_datetimeFormats, translations as pt_translations } from '@/shared/locale/pt';
import { useAppStore } from '@/store/modules/app';

export type TranslatedMessage = TranslateResult;

let instance: I18n;

export function createPlugin() {
  if (!instance) {
    instance = createI18n({
      legacy: false,
      locale: useAppStore().locale,
      fallbackLocale: 'fr',
      datetimeFormats: {
        en: en_datetimeFormats,
        es: es_datetimeFormats,
        it: it_datetimeFormats,
        fr: fr_datetimeFormats,
        pt: pt_datetimeFormats,
      },
      messages: {
        en: en_translations,
        es: es_translations,
        it: it_translations,
        fr: fr_translations,
        pt: pt_translations,
      },
    });
  }

  return instance;
}

export default function translator() {
  return createPlugin().global as Composer;
}
