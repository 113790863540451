import { localize, setLocale } from '@vee-validate/i18n';
import { email, required } from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';

import { validations as en_validations } from '@/shared/locale/en';
import { validations as es_validations } from '@/shared/locale/es';
import { validations as fr_validations } from '@/shared/locale/fr';
import { validations as it_validations } from '@/shared/locale/it';
import { validations as pt_validations } from '@/shared/locale/pt';
import { useAppStore } from '@/store/modules/app';

export default function () {
  configure({
    generateMessage: localize({
      en: { messages: en_validations },
      es: { messages: es_validations },
      it: { messages: it_validations },
      fr: { messages: fr_validations },
      pt: { messages: pt_validations },
    }),
  });

  defineRule('required', required);
  defineRule('email', email);

  setLocale(useAppStore().locale);
}
