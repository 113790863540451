import { AbortOptions, PaginationOptions } from '@/shared/dataProviders/common';
import {
  B2PWebCollection,
  B2PWebError,
  handleB2PWebResponse,
  mapToCollection,
} from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { ApiCommunityRequest } from '@/shared/dataProviders/partner/api';
import { mapToCommunityRequest } from '@/shared/dataProviders/partner/mappers';
import { CommunityInvitationPostValues } from '@/shared/dataProviders/partner/types';

export class CommunityRequestDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchAll(options?: AbortOptions & PaginationOptions) {
    const items = handleB2PWebResponse(
      await this.client.get<B2PWebCollection<ApiCommunityRequest>, B2PWebError>('/my/communities/requests', {
        params: {
          page: options?.page ? options.page : 1,
          perPage: options?.perPage ? options.perPage : 20,
        },
        signal: options?.signal,
      })
    );

    return mapToCollection(items, mapToCommunityRequest);
  }

  async count(options?: AbortOptions) {
    const { count } = handleB2PWebResponse(
      await this.client.get<{ count: number }, B2PWebError>('/my/communities/requests/count', {
        signal: options?.signal,
      })
    );

    return count;
  }

  async accept(data: CommunityInvitationPostValues) {
    handleB2PWebResponse(
      await this.client.post<void, CommunityInvitationPostValues, B2PWebError>('/my/communities/requests/accept', data)
    );
  }

  async refuse(customers: string[]) {
    handleB2PWebResponse(
      await this.client.delete<void, B2PWebError, { customers: string[] }>('/my/communities/requests/refuse', {
        data: { customers },
      })
    );
  }
}
