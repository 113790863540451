import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFeedbackStore = defineStore('feedback', () => {
  const isModalOpen = ref<boolean>(false);

  return {
    isModalOpen,
  };
});
