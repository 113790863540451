import { onScopeDispose, ref, unref } from 'vue';

import { MaybeRef } from '@/shared/composables/utils/types';
import window from '@/shared/window';

export function useMediaQuery(query: MaybeRef<string>) {
  const matches = ref(false);

  const handler = (event: MediaQueryListEvent) => {
    matches.value = event.matches;
  };

  const mediaQuery = window.matchMedia(unref(query));

  mediaQuery.addEventListener('change', handler);

  matches.value = mediaQuery.matches;

  onScopeDispose(() => {
    mediaQuery.removeEventListener('change', handler);
  });

  return matches;
}
