import axios from 'axios';

import { setupAuthentication } from '@/shared/api/util/interceptors/setupAuthentication';
import { setupUserLanguage } from '@/shared/api/util/interceptors/setupUserLanguage';
import config from '@/shared/config';
import { AxiosRestClient } from '@/shared/dataProviders/common/AxiosRestClient';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { PartnerOffersDataProvider } from '@/shared/dataProviders/search/PartnerOffersDataProvider';

import { EphemeralSearchDataProvider } from './EphemeralSearchDataProvider';
import { SearchEnumDataProvider } from './SearchEnumDataProvider';
import { SearchTemplateDataProvider } from './SearchTemplateDataProvider';

export * from './types';

let ephemeralSearchDataProvider: EphemeralSearchDataProvider;
export function useEphemeralSearchDataProvider(): EphemeralSearchDataProvider {
  if (!ephemeralSearchDataProvider) {
    ephemeralSearchDataProvider = new EphemeralSearchDataProvider(useRestClient());
  }
  return ephemeralSearchDataProvider;
}

let partnerOffersDataProvider: PartnerOffersDataProvider;
export function usePartnerOffersDataProvider(): PartnerOffersDataProvider {
  if (!partnerOffersDataProvider) {
    partnerOffersDataProvider = new PartnerOffersDataProvider(useRestClient());
  }
  return partnerOffersDataProvider;
}

let searchEnumDataProvider: SearchEnumDataProvider;
export function useSearchEnumDataProvider(): SearchEnumDataProvider {
  if (!searchEnumDataProvider) {
    searchEnumDataProvider = new SearchEnumDataProvider(useRestClient());
  }
  return searchEnumDataProvider;
}

let searchTemplateDataProvider: SearchTemplateDataProvider;
export function useSearchTemplateDataProvider(): SearchTemplateDataProvider {
  if (!searchTemplateDataProvider) {
    searchTemplateDataProvider = new SearchTemplateDataProvider(useRestClient());
  }
  return searchTemplateDataProvider;
}

let client: RestClient;
function useRestClient(): RestClient {
  if (!client) {
    const axiosInstance = axios.create({
      baseURL: config.urls.searchApi,
    });

    setupAuthentication(axiosInstance);
    setupUserLanguage(axiosInstance);

    client = new AxiosRestClient(axiosInstance);
  }
  return client;
}
