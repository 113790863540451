import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useCommunityRequestDataProvider } from '@/shared/dataProviders/partner';

export const usePartnerStore = defineStore('partner', () => {
  const dataProvider = useCommunityRequestDataProvider();
  const communityRequestsCount = ref<number | null>(null);

  return {
    communityRequestsCount,

    async loadCommunityRequestsCount(force: boolean = false) {
      if (force || communityRequestsCount.value === null) {
        communityRequestsCount.value = await dataProvider.count();
      }
    },

    removeCommunityRequestsCount(count: number) {
      if (communityRequestsCount.value) {
        communityRequestsCount.value -= count;
      }
    },
  };
});
