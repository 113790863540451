import { DateTime } from 'luxon';

import {
  Axis as ApiAxis,
  AxisLocation as ApiAxisLocation,
  Collection as ApiCollection,
  Comment as ApiComment,
  Customer as ApiCustomer,
  CustomerFile as ApiCustomerFile,
  CustomerScore as ApiCustomerScore,
  Document as ApiDocument,
  MyAxis as ApiMyAxis,
  MyCustomerFile as ApiMyCustomerFile,
  MyDocument as ApiMyDocument,
  SearchResult as ApiSearchResult,
  VehicleType as ApiVehicleType,
} from '@/shared/api/directory';
import config from '@/shared/config';
import { Collection } from '@/shared/dataProviders/common';
import {
  Axis,
  AxisLocation,
  Comment,
  Customer,
  CustomerFile,
  Document,
  MyAxis,
  MyCustomerFile,
  MyDocument,
  SearchResult,
  VehicleType,
} from '@/shared/dataProviders/directory';

export function composeCollection<Source, Target>(
  source: ApiCollection<Source>,
  composeItems: (items: Source[]) => Target[]
): Collection<Target> {
  return {
    items: composeItems(source.items),
    count: source.pagination.pageCount,
    totalCount: source.pagination.totalCount,
    page: source.pagination.currentPage,
    pageCount: Math.ceil(source.pagination.totalCount / source.pagination.limit),
    perPage: source.pagination.limit,
  };
}

export function composeArray<Source, Target>(
  source: ApiCollection<Source>,
  composeItems: (items: Source[]) => Target[]
): Target[] {
  return composeItems(source.items);
}

export function composeSearchResults(items: ApiSearchResult[]): SearchResult[] {
  return items.map((item) => ({
    ...item,
    dateInsert: item.dateInsert ? DateTime.fromISO(item.dateInsert) : null,
    logo: composeLogoUrl(item.logo),
  }));
}

export function composeScores(items: Record<string, ApiCustomerScore>): Record<string, string | null> {
  const scores: Record<string, string | null> = {};

  for (const item of Object.values(items)) {
    scores[item.customerId] = item.score;
  }

  return scores;
}

export function composeLogoUrl(path: string | null): string | null {
  if (!path) {
    return null;
  }

  const baseUrl = config.urls.customerLogo.endsWith('/')
    ? config.urls.customerLogo.slice(0, -1)
    : config.urls.customerLogo;

  path = path.startsWith('/') ? path.slice(1, path.length) : path;

  return `${baseUrl}/${path}`;
}

function composeWebsiteUrl(path: string | null): string | null {
  if (!path) {
    return null;
  }

  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }

  return `http://${path}`;
}

export function composeComments(items: ApiComment[]): Comment[] {
  return items.map((item) => composeComment(item));
}

export function composeComment(item: ApiComment): Comment {
  return {
    id: item.id,
    user: {
      id: item.userId,
      name: item.userName,
    },
    message: item.message,
    creationDate: DateTime.fromISO(item.createdAt),
  };
}

export function composeDocuments(items: ApiDocument[]): Document[] {
  return items.map((item) => composeDocument(item));
}

function composeDocument(item: ApiDocument): Document {
  return {
    type: {
      id: item.documentType.id,
      label: item.documentType.label,
      isMandatory: item.documentType.mandatory,
    },
    status: {
      label: item.documentStatus.label,
      value: item.documentStatus.value,
      isValid: item.documentStatus.isValid,
    },
    recall: {
      typeId: item.recall.typeId,
      typeLabel: item.recall.typeLabel,
      date: item.recall.date ? DateTime.fromISO(item.recall.date) : null,
    },
    expirationDate: item.expiredAt ? DateTime.fromISO(item.expiredAt) : null,
  };
}

export function composeMyDocuments(items: ApiMyDocument[]): MyDocument[] {
  return items.map((item) => composeMyDocument(item));
}

function composeMyDocument(item: ApiMyDocument): MyDocument {
  return {
    ...composeDocument(item),
    canBeSent: item.canBeSent,
  };
}

export function composeCustomerFile(source: ApiCustomerFile): CustomerFile {
  return {
    id: source.id,
    customer: composeCustomer(source.customer),
    certifications: source.certifications,
    axes: composeAxes(source.axes),
    vehicleTypes: composeVehicleTypes(source.vehicleTypes),
    languages: source.languages,
    activity: source.activity,
    directoryScore: source.directoryScore,
    managers: {
      charteringManagerName: source.managers.charteringManagerName,
      companyManagerName: source.managers.companyManagerName,
      itManagerName: source.managers.itManagerName,
      operationsManagerName: source.managers.operationsManagerName,
      salesManagerName: source.managers.salesManagerName,
    },
    url: composeWebsiteUrl(source.url),
    effective: source.effective,
    hasEcmr: source.hasEcmr,
    storageCapacity: source.storageArea,
    distribution: source.distribution,
    multimodalTransport: source.multimodalTransportation,
    isB2pSubcontractor: source.isB2pSubcontractor,
    isSubcontractor: source.isSubcontractor,
  };
}

export function composeMyCustomerFile(source: ApiMyCustomerFile): MyCustomerFile {
  return {
    customer: composeCustomer(source.customer),
    certifications: source.certifications || [],
    axes: composeMyAxes(source.axes),
    vehicleTypes: composeVehicleTypes(source.vehicleTypes),
    languages: source.languages,
    activity: source.activity,
    directoryScore: source.directoryScore,
    managers: {
      charteringManagerName: source.managers.charteringManagerName,
      companyManagerName: source.managers.companyManagerName,
      itManagerName: source.managers.itManagerName,
      operationsManagerName: source.managers.operationsManagerName,
      salesManagerName: source.managers.salesManagerName,
    },
    documentOwner: {
      name: source.documentOwner.name,
      email: source.documentOwner.email,
      phone: source.documentOwner.phone,
    },
    url: composeWebsiteUrl(source.url),
    effective: source.effective,
    hasEcmr: source.hasEcmr,
    storageCapacity: source.storageArea,
    distribution: source.distribution,
    multimodalTransport: source.multimodalTransportation,
    isB2pSubcontractor: source.isB2pSubcontractor,
    isSubcontractor: source.isSubcontractor,
  };
}

function composeCustomer(source: ApiCustomer): Customer {
  return {
    id: source.id,
    name: source.name,
    siret: source.siret,
    vatNumber: source.tva,
    napCode: source.napCode,
    address1: source.address1,
    address2: source.address2,
    address3: source.address3,
    city: source.city,
    zipCode: source.zipCode,
    country: source.country,
    phone: source.phone,
    fax: source.fax,
    logo: composeLogoUrl(source.logo),
    dateInsert: source.dateInsert ? DateTime.fromISO(source.dateInsert) : null,
  };
}

function composeAxes(sources: ApiAxis[]): Axis[] {
  return sources.map((source) => composeAxis(source));
}

function composeMyAxes(sources: ApiMyAxis[]): MyAxis[] {
  return sources.map((source) => composeMyAxis(source));
}

function composeMyAxis(source: ApiMyAxis): MyAxis {
  return {
    id: source.id,
    ...composeAxis(source),
  };
}

function composeAxis(source: ApiAxis): Axis {
  return {
    lading: composeAxisLocation(source.lading),
    delivery: composeAxisLocation(source.delivery),
  };
}

function composeAxisLocation(source: ApiAxisLocation): AxisLocation {
  return {
    country: source.country,
    zones: source.zones,
  };
}

function composeVehicleTypes(sources: ApiVehicleType[]): VehicleType[] {
  return sources.map((source) => composeVehicleType(source));
}

function composeVehicleType(source: ApiVehicleType): VehicleType {
  return {
    id: source.id,
    typeId: source.typeId,
    quantity: source.quantity,
    characteristics: source.characteristics,
    comments: source.comments,
  };
}
