import { AbortOptions, Collection, PaginationOptions, SortingOptions } from '@/shared/dataProviders/common';
import {
  B2PWebCollection,
  B2PWebError,
  handleB2PWebResponse,
  mapToCollection,
} from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import { CommunityPartner, CommunityPartnerFilters } from '@/shared/dataProviders/partner';
import { ApiCommunityPartner } from '@/shared/dataProviders/partner/api';
import { mapToCommunityPartner } from '@/shared/dataProviders/partner/mappers';

export class CommunityPartnerDataProvider {
  constructor(private readonly client: RestClient) {}

  async fetchAll(
    filters?: CommunityPartnerFilters,
    options?: AbortOptions & PaginationOptions & SortingOptions
  ): Promise<Collection<CommunityPartner>> {
    const items = handleB2PWebResponse(
      await this.client.get<B2PWebCollection<ApiCommunityPartner>, B2PWebError>('/my/communities/partners', {
        params: {
          exchanges: filters?.exchanges,
          enabled: filters?.enabled,
          customerName: filters?.customerName,
          customerCountry: filters?.customerCountry,
          customerZones: filters?.customerZones ? [filters?.customerZones] : undefined,
          page: options?.page,
          perPage: options?.perPage,
          sort: options?.sort,
        },
        signal: options?.signal,
      })
    );

    return mapToCollection(items, mapToCommunityPartner);
  }

  async removeAll(items: CommunityPartner[], options?: AbortOptions): Promise<void> {
    return handleB2PWebResponse(
      await this.client.delete<void, B2PWebError>('/my/communities/partners', {
        data: items.map((item: CommunityPartner) => ({
          exchangeId: item.id,
          memberId: item.partner.id,
        })),
        signal: options?.signal,
      })
    );
  }
}
