import {
  composeCollection,
  composeConsultation,
  composeDeletedOffer,
  composeOffer,
  composeOfferAction,
  composeSketchyOffer,
  composeTemplate,
} from '@/shared/api/offer/mappers';
import {
  Consultation,
  DeletedOffer,
  Offer,
  OfferAction,
  Profile,
  SketchyOffer,
  Template,
} from '@/shared/api/offer/types';
import {
  ApiActiveSearch,
  ApiCollectionResponse,
  ApiConsultant,
  ApiConsultation,
  ApiDeletedOffer,
  ApiHistoryFilters,
  ApiOffer,
  ApiOfferAction,
  ApiPaginatedCollectionResponse,
  ApiProfile,
  ApiRealOffer,
  ApiSketchyOffer,
  ApiTemplate,
  OfferNotFoundError,
} from '@/shared/api/offer/types-api';
import { cancellable } from '@/shared/api/util/cancellable';

import client, { isAxiosError } from './client';

export interface Collection<T> {
  items: T[];
  page: number;
  pageCount: number;
  limit: number;
  totalCount: number;
}

export interface RequestCancellationOptions {
  signal?: AbortSignal;
}

export interface RequestPagination {
  page?: number;
  perPage?: number;
}

export interface RequestSorting {
  sort?: string;
}

export async function fetchCoworkers(): Promise<Record<string, string>> {
  const { data } = await client.get<{ key: string; value: string }[]>('enum/coworkers');

  return data.reduce((list: Record<string, string>, { key, value }: { key: string; value: string }) => {
    list[key] = value;
    return list;
  }, {});
}

export async function fetchPostProfiles(): Promise<Profile[]> {
  const { data } = await client.get<ApiProfile[]>('enum/customersProfiles');

  return data.map(
    ({ key, value, ...profile }: ApiProfile): Profile => ({
      key,
      value,
      isDefault: profile.default,
    })
  );
}

export interface OfferListFilters {
  ids?: string[];
  userId?: string;
  q?: string;
  fromCountry?: string;
  fromZones?: string[];
  toCountry?: string;
  toZones?: string[];
  goodType?: string;
  truckTypes?: string[];
  maxWeight?: number;
  maxLength?: number;
  maxVolume?: number;
}

export async function fetchSketchyOffers(
  { page = 1, perPage = 40, sort, ...filters }: OfferListFilters & RequestPagination & RequestSorting = {},
  signal?: AbortSignal
): Promise<Collection<SketchyOffer>> {
  const { data } = await cancellable(
    client.get<ApiPaginatedCollectionResponse<ApiSketchyOffer>>(`offers`, {
      params: {
        ...filters,
        page,
        per_page: perPage,
        sort,
      },
      signal,
    })
  );

  return {
    items: data.items.map(composeSketchyOffer),
    page: data.pagination.currentPage,
    pageCount: data.pagination.pageCount,
    limit: data.pagination.limit,
    totalCount: data.pagination.totalCount,
  };
}

export async function fetchOffer(id: string): Promise<Offer | undefined> {
  try {
    const { data } = await client.get<ApiOffer>(`offers/${id}`);
    return composeOffer(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return undefined;
    }

    throw e;
  }
}

export async function fetchOfferActions(
  id: string,
  options: RequestCancellationOptions & RequestPagination = {}
): Promise<Collection<OfferAction>> {
  const { data } = await cancellable(
    client.get<ApiPaginatedCollectionResponse<ApiOfferAction>>(`offers/${id}/actions`, {
      params: {
        page: options.page,
        per_page: options.perPage,
      },
      signal: options.signal,
    })
  );
  return composeCollection(data, composeOfferAction);
}

export async function fetchDeletedOffers(
  { page = 1, perPage = 40, sort, ...filters }: OfferListFilters & RequestPagination & RequestSorting = {},
  signal?: AbortSignal
): Promise<Collection<DeletedOffer>> {
  const { data } = await cancellable(
    client.get<ApiPaginatedCollectionResponse<ApiDeletedOffer>>('deletedOffers', {
      params: {
        ...filters,
        page,
        per_page: perPage,
        sort,
      },
      signal,
    })
  );

  return {
    items: data.items.map(composeDeletedOffer),
    page: data.pagination.currentPage,
    pageCount: data.pagination.pageCount,
    limit: data.pagination.limit,
    totalCount: data.pagination.totalCount,
  };
}

export async function fetchDeletedOffer(id: string): Promise<DeletedOffer | undefined> {
  try {
    const { data } = await client.get<ApiDeletedOffer>(`deletedOffers/${id}`);

    return composeDeletedOffer(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return undefined;
    }

    throw e;
  }
}

export async function fetchDeletedOfferActions(
  id: string,
  options: RequestCancellationOptions & RequestPagination = {}
): Promise<Collection<OfferAction>> {
  const { data } = await cancellable(
    client.get<ApiPaginatedCollectionResponse<ApiOfferAction>>(`deletedOffers/${id}/actions`, {
      params: {
        page: options.page,
        per_page: options.perPage,
      },
      signal: options.signal,
    })
  );
  return composeCollection(data, composeOfferAction);
}

export async function fetchTemplates({ page = 1, perPage = 40 }: RequestPagination = {}): Promise<
  Collection<Template>
> {
  const { data } = await client.get<ApiPaginatedCollectionResponse<ApiTemplate>>(`templateOffers`, {
    params: {
      page,
      per_page: perPage,
    },
  });

  return {
    items: data.items.map(composeTemplate),
    page: data.pagination.currentPage,
    pageCount: data.pagination.pageCount,
    limit: data.pagination.limit,
    totalCount: data.pagination.totalCount,
  };
}

export type OfferType = 'offers' | 'deletedOffers';

export async function fetchConsultations(offerId: string, typeHint: OfferType): Promise<Consultation[]> {
  try {
    const { data } = await client.get<ApiCollectionResponse<ApiConsultation>>(`offers/${offerId}/consultants`, {
      params: { typeHint },
    });

    return data.items.map(composeConsultation);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      throw new OfferNotFoundError(e.response.data.message);
    }

    throw e;
  }
}

export async function fetchRealOffer(offerId: string): Promise<ApiRealOffer> {
  try {
    const { data } = await client.get<ApiRealOffer>(`realOffers/${offerId}`);

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      throw new OfferNotFoundError(e.response.data.message);
    }

    throw e;
  }
}

export async function generateCharterConfirmAsPdf(id: string): Promise<Blob> {
  try {
    const { data } = await client.get<Blob>(`realOffers/${id}/pdf`, {
      responseType: 'blob',
    });

    return data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      throw new OfferNotFoundError(e.response.data.message);
    }

    throw e;
  }
}

export async function fetchActiveSearchHistory(
  params: ApiHistoryFilters & RequestPagination,
  { signal }: RequestCancellationOptions
): Promise<ApiPaginatedCollectionResponse<ApiActiveSearch>> {
  const { data } = await cancellable(
    client.get<ApiPaginatedCollectionResponse<ApiActiveSearch>>('history/activeSearches', {
      params,
      signal,
    })
  );
  return data;
}

export async function fetchWorkHistoryWithCarriers(
  params: ApiHistoryFilters & RequestPagination,
  { signal }: RequestCancellationOptions
): Promise<ApiPaginatedCollectionResponse<ApiRealOffer>> {
  const { data } = await cancellable(
    client.get<ApiPaginatedCollectionResponse<ApiRealOffer>>('history/carriers', {
      params,
      signal,
    })
  );
  return data;
}

export async function fetchTopConsultantsHistory(
  params: ApiHistoryFilters & RequestPagination,
  { signal }: RequestCancellationOptions
): Promise<ApiPaginatedCollectionResponse<ApiConsultant>> {
  const { data } = await cancellable(
    client.get<ApiPaginatedCollectionResponse<ApiConsultant>>('history/consultants', {
      params,
      signal,
    })
  );
  return data;
}

export async function generateS3PWebToken(signal?: AbortSignal): Promise<string> {
  const { data } = await cancellable(
    client.get<{ accessToken: string }>('s3p/token', {
      signal,
    })
  );

  return data.accessToken;
}
