import { DateTime } from 'luxon';

import { getTimeSettings } from '@/shared/dataProviders/admin';

let timeSkew = 0;

export async function loadTimeSettings(): Promise<void> {
  const localTimeBefore = new Date().getTime();
  const settings = await getTimeSettings();
  const localTimeAfter = (localTimeBefore + new Date().getTime()) / 2;

  timeSkew = Math.floor(localTimeAfter / 1000) - settings.time;
}

export function syncedNow(): DateTime {
  return DateTime.now().plus({ second: timeSkew });
}
